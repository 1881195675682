<template>
  <b-card-code title="Insecure Port">
    <b-table responsive id="orgTable" :fields="fields" :items="formattedInsecurePortList" >
        <template #cell(2)="data">
        {{ data.item[2] }}
      </template>
      <template #cell(1)="data">
        {{ data.item[1] }}
      </template>
    </b-table>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable, BAvatar } from "bootstrap-vue";
export default {
  components: {
    BCardCode,
    BTable,
    BAvatar,
  },
  props: {
    insecureportList:{
      type:Array,
      required:true,
    },
  },
  data() {
    return {
      // Call orgaization API and return in this format
      items: [],
      fields: [
        { key: "2", label: "Port" },
        { key: "1", label: "Description" },
      ],
    };
  },
   computed: {
    formattedInsecurePortList() {
      return this.insecureportList.map(([_, description, port]) => ({
        1: description,
        2: port,
      }));
    },
  },

  methods: {

  },
};
</script>