var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"AssetEditForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.scanNow($event)}}},[(!_vm.is_group)?_c('b-form-group',{attrs:{"label-for":"urls"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("URLs")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")]),_c('b-button',{staticClass:"ml-1",staticStyle:{"padding":"0.186rem 1rem !important"},attrs:{"variant":"outline-success","size":"sm"},on:{"click":function($event){return _vm.gotoUrl()}}},[_vm._v("+ ")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"url","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticStyle:{"width":"100%"},attrs:{"label":"text","placeholder":"--Select URL--","options":_vm.url_options,"autocomplete":"","reduce":function (urlName) { return urlName.value; }},model:{value:(_vm.urlName),callback:function ($$v) {_vm.urlName=$$v},expression:"urlName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"","label-for":"is_authorised"}},[_c('b-form-checkbox',{attrs:{"id":"checkbox-1","name":"is_authorised","value":"true","unchecked-value":"false"},model:{value:(_vm.is_authorised),callback:function ($$v) {_vm.is_authorised=$$v},expression:"is_authorised"}},[_vm._v(" Authorised Scan ")])],1),(_vm.is_authorised === true || _vm.is_authorised === 'true')?_c('b-form-group',{attrs:{"label":"Username","label-for":"username"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Username")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","state":errors.length > 0 ? false : null},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.is_authorised === true || _vm.is_authorised === 'true')?_c('b-form-group',{attrs:{"label":"Password","label-for":"password"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Password")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password","state":errors.length > 0 ? false : null},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"","label-for":"is_scoped"}},[_c('b-form-checkbox',{attrs:{"id":"checkbox-2","name":"is_scoped","value":"true","unchecked-value":"false"},model:{value:(_vm.is_scoped),callback:function ($$v) {_vm.is_scoped=$$v},expression:"is_scoped"}},[_vm._v(" Scoped Scan ")])],1),(_vm.is_scoped === true || _vm.is_scoped === 'true')?_c('b-form-group',{attrs:{"label":"Include","label-for":"include"}},[_c('b-form-input',{attrs:{"id":"include"},model:{value:(_vm.include),callback:function ($$v) {_vm.include=$$v},expression:"include"}})],1):_vm._e(),(_vm.is_scoped === true || _vm.is_scoped === 'true')?_c('b-form-group',{attrs:{"label":"Exclude","label-for":"exclude"}},[_c('b-form-input',{attrs:{"id":"exclude"},model:{value:(_vm.exclude),callback:function ($$v) {_vm.exclude=$$v},expression:"exclude"}})],1):_vm._e(),_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":invalid || _vm.disable || _vm.urlName === null,"size":"sm"}},[(_vm.isLoading)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v(" Please wait")],1):_c('span',[_vm._v("Scan")])])],1)]}}])}),_c('b-modal',{attrs:{"title":"Add New URL","hide-footer":"","no-close-on-backdrop":"","size":"lg"},model:{value:(_vm.urlModal),callback:function ($$v) {_vm.urlModal=$$v},expression:"urlModal"}},[_c('CreateAssetUrl',{attrs:{"assetId":_vm.asset_id,"closeModal":_vm.closeModal}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }