<template>
  <b-col cols="12">
    <b-card-code class="border-primary" title="SSL Score" no-body>
      <b-table
        responsive
        id="SSLTable"
        :fields="sslLabs_fields"
        :items="sslLabs_items"
        :per-page="sslLabs_perPage"
        :current-page="sslLabs_currentPage"
        hover
      >
        <template #cell(index)="data">
          {{ data.index + 1 + sslLabs_perPage * (sslLabs_currentPage - 1) }}
        </template>
        <template #cell(name)="data">
          <a class="text-primary h5" @click="showSidebar(data)">{{
            data.item.ipAddress
          }}</a>
          <br />
          <span>{{ data.item.statusMessage }}</span>
        </template>
        <template #cell(testtime)="data">
          <p class="mb-0">
            {{ data.item.details.httpTransactions[0].responseHeaders[1].value }}
            <br /><span
              ><b>Duration:</b> {{ data.item.duration | formattime }}</span
            >
          </p>
        </template>
        <template #cell(grade)="data">
          <h4 v-if="data.item.grade === 'A+' || data.item.grade === 'A'">
            <b class="text-success">{{ data.item.grade }}</b>
          </h4>
          <h4 v-else-if="data.item.grade === 'B'">
            <b style="color: #ffff00">{{ data.item.grade }}</b>
          </h4>
          <h4 v-else-if="data.item.grade === 'C'">
            <b class="text-warning">{{ data.item.grade }}</b>
          </h4>
          <h4 v-else-if="data.item.grade === 'F'">
            <b class="text-danger">{{ data.item.grade }}</b>
          </h4>
          <h4
            v-else-if="
              data.item.grade === 'A-' ||
              data.item.grade === 'T' ||
              data.item.grade === 'M'
            "
          >
            <b style="color:#A020F0">{{ data.item.grade }}</b>
          </h4>
          <h4 v-else>
            {{ data.item.grade }}
          </h4>
        </template>
      </b-table>
      <!-- @click="showSidebar(data)"d -->
      <!-- <b-pagination
        v-model="sslLabs_currentPage"
        :total-rows="sslLabs_rows"
        :per-page="sslLabs_perPage"
        :align="pagination_pos"
        aria-controls="SSLTable"
      />  -->

      <!-- Right Sidebar starts -->
      <b-sidebar
        id="sidebar-task-handler"
        sidebar-class="sidebar-lg"
        bg-variant="white"
        shadow
        backdrop
        no-header
        right
      >
        <!-- <h1>{{sslLabs_items}}</h1> -->
        <sslLabsDetail
          :sslLabs="sslLabs"
          :ssl_items="ssl_items"
          :closeSidebar="closeSidebar"
        />

        <!-- <esecurtityDetail
            :esecurtity="esecurtity"
            :callBack="load"
            :closeSidebar="closeSidebar"
            :asset_id="asset_id"
            :organization="parseInt(organization_id)"
          /> -->
      </b-sidebar>
    </b-card-code>
  </b-col>
</template>



<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BSidebar,
  VBToggle,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SslLabsDetail from "../SslLab_Details.vue";
import moment from "moment";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    // VBToggle,
    SslLabsDetail,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      pagination_pos: "center",
      organization_id: this.$store.state.app.org_id,
      asset_id: "",

      // Scanner attachment modal
      task_title: "",
      polling: null,

      sslLabs: {},
      sslLabs_items: [],
      sslLabs_currentPage: 1,
      sslLabs_perPage: 10,
      sslLabs_rows: 0,
      sslLabs_fields: [
        { key: "index", label: "#" },
        { key: "name", label: "Server" },
        { key: "testtime", label: "Test Time" },
        { key: "grade", label: "Grade" },
      ],
    };
  },
  // props: {
  //   sslLabs: {
  //     type: Object,
  //     required: true,
  //   },
  //   closeSidebar: {
  //     type: Function,
  //     required: true,
  //   },
  // },
  created() {
    this.load();
  },
  filters: {
    formattime: function (ms) {
      let seconds = (ms / 1000).toFixed(1);
      let minutes = (ms / (1000 * 60)).toFixed(1);
      let hours = (ms / (1000 * 60 * 60)).toFixed(1);
      let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
      if (seconds < 60) return seconds + " Sec";
      else if (minutes < 60) return minutes + " Min";
      else if (hours < 24) return hours + " Hrs";
      else return days + " Days";
    },
  },
  methods: {
    // addVuln: function () {
    //   this.$router.push({ name: "Add Vulnerability" });
    // },

    load: function () {
      this.asset_id = parseInt(this.$route.params.id);
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/org-asset/" +
          this.$route.params.id +
          "/",
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res.data.ssl_scan_result[0].scan_result, "SSL ");
        // this.organization_id = res.data.assetOwner.profile.organization;
        // this.sslLabs_items = res.data.ssl_scan_result[0].scan_result;
        this.sslLabs_items = res.data.ssl_scan_result[0].scan_result.endpoints;
        this.ssl_items = res.data.ssl_scan_result[0].scan_result.certs;

        // this.sslLabs_rows = res.data.emailsecuritydata.length;
      });
      //  res.data.map(function (value, key) {
      //       let org = {
      //         value: res.data[key].org_id,
      //         text: res.data[key].org_name,
      //       };
      //       self.organizations.push(org);
      //     });

      // this.loadScans();
      // this.loadSchedules();
    },
    showSidebar: function (data) {
      this.sslLabs = data.item;
      console.log("sidebar ssl", data);
      // this.$refs['taskSideBar'].show();
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
      // this.isTaskHandlerSidebarActive = true
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
    },
  },
  // reference: https://renatello.com/vue-js-polling-using-setinterval/
};
</script>

<style lang="scss" scoped>
</style>
