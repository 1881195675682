<template>
  <b-row class="match-height" id="assetOverview">
    <b-col cols="12">
      <div class="d-flex justify-content-end my-1">
        <!-- <b-button
          class="mr-1 no-print"
          type="button"
          variant="outline-primary"
          size="sm"
          @click="printAssetOverview()"
        >
          Print
        </b-button> -->
        <!-- <b-button
          v-if="!assetDetails.is_verified"
          class="mr-1"
          type="button"
          variant="success"
          size="sm"
          @click="openVerification()"
        >
          Verify
        </b-button> -->
        <router-link :to="'/assets/edit/' + this.asset_id">
          <b-button type="button" variant="primary" size="sm"> Edit </b-button>
        </router-link>
        <b-button class="ml-1" @click="goBack()" size="sm" variant="primary"
          >Back</b-button
        >
      </div>
    </b-col>
    <b-col cols="12">
      <b-card class="border-primary">
        <b-row>
          <b-col cols="12" xl="12">
            <h4 class="mb-0">
              {{ asset_name }}
            </h4>
          </b-col>
          <!-- Asset Info: Left col -->
          <b-col
            cols="12"
            xl="4"
            class="d-flex justify-content-between flex-column"
          >
            <b-card no-body>
              <b-card-header
                class="d-flex justify-content-between align-items-center pt-75 pb-25"
              >
                <!-- <h4 class="mb-0">
                  {{ asset_name }}
                </h4> -->
                <div>
                  <b-badge
                    variant="light-primary"
                    class="mr-1"
                    v-if="asset_type != null"
                  >
                    {{ asset_type.asset_type_name }}
                  </b-badge>
                  <b-badge
                    variant="success"
                    v-if="assetDetails.is_verified === true"
                    >Verified</b-badge
                  >
                  <b-badge
                    variant="warning"
                    v-if="assetDetails.is_verified === false"
                    >Not Verified</b-badge
                  >
                </div>
              </b-card-header>

              <b-card-body>
                <ul class="list-unstyled my-1">
                  <li>
                    <span class="align-middle"
                      >Nick name: {{ asset_nick_name }}</span
                    >
                  </li>
                  <li>
                    <span class="align-middle"
                      >Organization: {{ organization }}</span
                    >
                  </li>
                  <li class="my-25">
                    <span class="align-middle">Owner: {{ asset_owner }}</span>
                  </li>

                  <li class="my-25">
                    <span class="align-middle">Risk level: </span>
                    <!-- <b-badge variant="light-warning">{{ risk_level }}</b-badge> -->
                    <b-badge
                      style="background-color: #991d28"
                      v-if="risk_level == 5"
                      >Critical</b-badge
                    >
                    <b-badge variant="danger" v-else-if="risk_level == 4"
                      >High</b-badge
                    >
                    <b-badge variant="warning" v-else-if="risk_level == 3"
                      >Medium</b-badge
                    >
                    <b-badge variant="primary" v-else-if="risk_level == 2"
                      >Low</b-badge
                    >
                    <b-badge variant="success" v-else-if="risk_level == 1"
                      >Safe</b-badge
                    >
                    <b-badge variant="secondary" v-else>Unknown</b-badge>
                  </li>
                  <li class="my-25">
                    <span class="align-middle">Exposure: </span>
                    <b-badge
                      variant="info"
                      v-if="assetDetails.asset_grouptype == 'external'"
                      >Internet Facing</b-badge
                    >
                    <b-badge variant="primary" v-else>Internal</b-badge>
                  </li>
                  <li class="my-25" v-if="networkLocation != ''">
                    <span class="align-middle"
                      >Network Location: {{ networkLocation }}</span
                    >
                  </li>
                  <li
                    class="my-25"
                    v-if="assetDetails.ssl_certificate_expiry_date != null "
                  >
                    <span class="align-middle">SSL Cert Expiry: </span>
                    <b-badge
                      :variant="
                        getExpiryBadgeVariant(
                          assetDetails.ssl_certificate_expiry_date
                        )
                      "
                    >
                      {{
                        assetDetails.ssl_certificate_expiry_date | formatdate
                      }}
                    </b-badge>
                  </li>
                </ul>
                <b-button variant="primary" block @click="gotoAssests()">
                  Back to Assets
                </b-button>
              </b-card-body>
            </b-card>
          </b-col>

          <!-- Right Col: Ports Info -->
          <b-col cols="12" xl="4">
            <b-card no-body class="ports_sec">
              <b-card-header
                class="d-flex justify-content-between align-items-center pt-75 pb-25"
              >
                <b-badge variant="light-primary"> Open Ports </b-badge>
                <!-- <small class="text-muted w-100">July 22, 2021</small> -->
              </b-card-header>

              <b-card-body>
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="BoxIcon" class="mr-75" />
                      <span class="font-weight-bold">Port</span>
                    </th>
                    <td class="pb-50">IP</td>
                  </tr>
                  <tr v-for="item in open_ports" :key="item.port">
                    <th class="pb-50">
                      <span class="font-weight-bold">{{ item.port }}</span>
                    </th>
                    <td class="pb-50">
                      {{ item.ip | replace("-", ".") }}
                    </td>
                  </tr>
                </table>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col cols="12" xl="4">
            <b-card no-body class="ports_sec">
              <b-card-header
                class="d-flex justify-content-between align-items-center pt-75 pb-25"
              >
                <b-badge variant="light-primary">URLs </b-badge>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-success"
                  v-b-tooltip.hover.top="'Add New Asset'"
                  class="btn-icon"
                  size="sm"
                  @click="gotoUrl()"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </b-card-header>
              <b-card-body>
                <div
                  class="d-flex justify-content-center mb-1"
                  v-if="loadingUrl"
                >
                  <b-spinner class="float-right" label="Floated Right" />
                </div>
                <div v-else>
                  <table
                    class="mt-2 mt-xl-0 w-100"
                    v-if="this.asset_url_list.length > 0"
                  >
                    <tr>
                      <th class="pb-50">
                        <feather-icon icon="GlobeIcon" class="mr-75" />
                        <span class="font-weight-bold">Name</span>
                      </th>
                      <td class="pb-50">Action</td>
                    </tr>
                    <tr v-for="item in asset_url_list" :key="item.url_id">
                      <th class="pb-50">
                        <span class="font-weight-bold">{{ item.url }}</span>
                      </th>
                      <td class="pb-50">
                        <b-button
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          variant="flat-danger"
                          class="btn-icon"
                          size="sm"
                          @click="gotoDelete(item.url_id)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          variant="flat-warning"
                          class="btn-icon"
                          size="sm"
                          @click="gotoEditUrl(item.url_id)"
                        >
                          <feather-icon icon="EditIcon" />
                        </b-button>
                      </td>
                    </tr>
                  </table>
                  <div v-else>
                    <div
                      class="w-100 d-flex flex-column align-items-center justify-content-center py-2 border-secondary rounded"
                    >
                      <feather-icon icon="GlobeIcon" size="25" class="mb-50" />
                      <h4 class="font-weight-bolder">No URLs Found</h4>
                      <p>
                        <a
                          style="color: #28c76f !important"
                          class="font-weight-bold text-success"
                          @click="gotoUrl()"
                          >Click here</a
                        >
                        to add new URL
                      </p>
                    </div>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
    </b-col>

    <!-- <b-col cols="12" v-if="isTabVisible">
      <b-tabs v-model="assetDetailTab">
        <b-tab title="Overview" active>
          <AssetOverview :overviewList="overviewList" />
        </b-tab>
        <b-tab title="Vulnerabilities" lazy>
          <Vulnerability />
        </b-tab>
        <b-tab title="Attack Surface" lazy>
          <AttackSurface
            :asset_id="asset_id"
            :asset="assetId"
            :asset_name="asset_name"
          />
        </b-tab>
        <b-tab title="Port Baseline" lazy>
          <ScanBaseline />
        </b-tab>

        <b-tab title="Email Security" lazy>
          <EmailSecurityResult />
        </b-tab>
        <b-tab title="IP Score" lazy>
          <IPReputationResult />
        </b-tab>
        <b-tab title="SSL Score" lazy>
          <SslLabsResult />
        </b-tab>
        <b-tab title="Scan & Schedules" lazy>
          <Scan_and_Schedule :loadAssetUrls="loadAssetUrls" />
        </b-tab>

        <b-tab
          title="Access"
          lazy
          v-if="
            this.$store.state.app.user.permissions.includes(
              'asset.list_all_org_assets'
            )
          "
        >
          <Access
            :asset_id="asset_id"
            :asset="assetId"
            :assetNameAndId="assetNameAndId"
          />
        </b-tab>
        <b-tab title="Logs" lazy>
          <Scanlogs />
        </b-tab>
      </b-tabs>
    </b-col> -->

    <b-col cols="12">
      <b-tabs v-model="assetDetailTab">
        <template>
          <span v-for="(tab, index) in computedTabs" :key="index">
            <b-tab v-if="tab.visible" :title="tab.title" :lazy="tab.lazy">
              <component :is="tab.component" v-bind="tab.props" />
            </b-tab>
          </span>
        </template>
      </b-tabs>
    </b-col>
    <b-modal
      ref="modal-verify"
      title="Verify Domain"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <VerifyDomain :assetId="asset_id" :callBack="callBack" />
    </b-modal>
    <b-modal
      title="Add New URL"
      hide-footer
      no-close-on-backdrop
      size="lg"
      v-model="urlModal"
    >
      <CreateAssetUrl
        :assetId="asset_id"
        :closeModal="closeModal"
        :loadAssetUrls="loadAssetUrls"
      />
    </b-modal>
    <b-modal
      title="Update URL"
      hide-footer
      no-close-on-backdrop
      size="lg"
      v-model="editUrlModal"
    >
      <EditAssetUrl
        :assetId="asset_id"
        :closeModal="closeEditModal"
        :urlId="urlId"
      />
    </b-modal>
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text> Do you really want to delete this URL? </b-card-text>
      <div class="d-flex justify-content-end mt-1">
        <b-button @click="deleteUrl(urlId)" variant="danger" size="sm">
          <span v-if="isLoading"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else>Delete</span>
        </b-button>
      </div>
    </b-modal>
  </b-row>
</template>


<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BSidebar,
  VBToggle,
  BTabs,
  BTab,
  BSpinner,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import moment from "moment";
import AssetOverview from "./components/scan/AssetOverview.vue";
import Vulnerability from "./components/scan/Vulnerability.vue";
import Scan_and_Schedule from "./components/scan/Scan_and_Schedule.vue";
import Scanlogs from "./components/scan/ScanLogs.vue";
import Access from "./components/scan/Access.vue";
import ScanBaseline from "./components/scan/ScanBaseline.vue";
import EmailSecurityResult from "./components/scan/EmailSecurityResult.vue";
import IPReputationResult from "./components/scan/IPReputationResult.vue";
import SslLabsResult from "./components/scan/SslLabsResult.vue";
import VerifyDomain from "../PublicSignUp/verify.vue";
import AttackSurface from "./components/AttackSurfaceDetails.vue";
import CreateAssetUrl from "./components/asset_url/add.vue";
import EditAssetUrl from "./components/asset_url/edit.vue";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    BTabs,
    BTab,
    AssetOverview,
    Vulnerability,
    Scan_and_Schedule,
    Scanlogs,
    ScanBaseline,
    EmailSecurityResult,
    IPReputationResult,
    SslLabsResult,
    Access,
    VerifyDomain,
    AttackSurface,
    CreateAssetUrl,
    EditAssetUrl,
    BSpinner,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      userId: localStorage.getItem("userid"),
      is_staff_login: this.$store.state.app.user.is_staff,
      asset_id: this.$route.params.id,
      assetId: [],

      //   asset_id: "",
      asset_name: "",
      asset_nick_name: "",
      risk_level: "",
      asset_type: "",
      asset_owner: "",
      last_scanned: "",
      organization: "",
      organization_id: "",
      pagination_pos: "center",
      open_ports: [],
      agent_type: "",
      assetDetails: {},
      overviewList: false,
      networkLocation: "",
      urlModal: false,
      asset_url_list: [],
      openDeleteModal: false,
      urlId: null,
      editUrlModal: false,
      loading: false,
      loadingUrl: false,
      assetNameAndId: {},
      assetDetailTab: 0,
    };
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YYYY");
      }
    },
    replace: function (ip, rep, repWith) {
      const result = ip.split(rep).join(repWith);
      return result;
    },
  },
  computed: {
    computedTabs() {
      const tabs = [
        {
          title: "Overview",
          component: "AssetOverview",
          lazy: false,
          visible: true,
          props: { overviewList: this.overviewList },
        },
        {
          title: "Vulnerabilities",
          component: "Vulnerability",
          lazy: true,
          visible: true,
        },
        {
          title: "Attack Surface",
          component: "AttackSurface",
          lazy: true,
          visible: true,
          props: {
            asset_id: this.asset_id,
            asset: this.assetId,
            asset_name: this.asset_name,
          },
        },
        {
          title: "Port Baseline",
          component: "ScanBaseline",
          lazy: true,
          visible: true,
        },
        {
          title: "Email Security",
          component: "EmailSecurityResult",
          lazy: true,
          visible: true,
        },
        {
          title: "IP Score",
          component: "IPReputationResult",
          lazy: true,
          visible: true,
        },
        {
          title: "SSL Score",
          component: "SslLabsResult",
          lazy: true,
          visible: true,
        },
        {
          title: "Scan & Schedules",
          component: "Scan_and_Schedule",
          lazy: true,
          visible: true,
          props: {
            loadAssetUrls: this.loadAssetUrls,
            asset_name: this.asset_name,
            assetDetails: this.assetDetails.asset_grouptype,
          },
        },
        {
          title: "Access",
          component: "Access",
          lazy: true,
          visible: this.$store.state.app.user.permissions.includes(
            "asset.list_all_org_assets"
          ),
          props: {
            asset_id: this.asset_id,
            asset: this.assetId,
            assetNameAndId: this.assetNameAndId,
          },
        },
        { title: "Logs", component: "Scanlogs", lazy: true, visible: true },
      ];

      // Conditionally set visibility for specific tabs
      if (
        !this.isIP(this.asset_name) &&
        this.assetDetails.asset_grouptype === "internal"
      ) {
        tabs[2].visible = false;
        tabs[4].visible = false;
        tabs[5].visible = false;
        tabs[6].visible = false;
      } else if (
        this.isIP(this.asset_name) &&
        this.assetDetails.asset_grouptype === "internal"
      ) {
        tabs[2].visible = false;
        tabs[4].visible = false;
        tabs[5].visible = false;
        tabs[6].visible = false;
      } else if (
        this.isIP(this.asset_name) &&
        this.assetDetails.asset_grouptype === "external"
      ) {
        tabs[4].visible = false; // Hide 'Attack Surface' tab
        tabs[6].visible = false; // Hide 'IP Score' tab
      }

      return tabs;
    },
  },
  created() {
    this.assetId.push(parseInt(this.$route.params.id));
    this.load();
  },
  methods: {
    load: function () {
      this.open_ports = [];
      this.asset_id = parseInt(this.$route.params.id);
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/org-asset/" +
          this.$route.params.id +
          "/",
      };
      var self = this;
      this.$http(options).then((res) => {
        this.asset_name = res.data.asset_name;
        this.asset_nick_name = res.data.asset_nickname;
        this.risk_level = res.data.risk_level;
        this.asset_type = res.data.assetType;
        this.asset_owner = res.data.assetOwner.full_name;
        this.assetDetails = res.data;
        this.organization = res.data.org_name;
        this.networkLocation = res.data.network_location_name;
        // this.organization_id = res.data.assetOwner.profile.organization;
        this.loadAssetUrls();

        this.assetNameAndId = {
          asset_id: res.data.asset_id,
          asset_name: res.data.asset_name,
        };

        if (res.status === 200) {
          this.overviewList = true;
        }

        if (res.data.baseline.length) {
          // This for managing the basline refesh automatically
          self.open_ports = [];
          res.data.baseline[0].base_line_entries.map(function (value, key) {
            if (
              res.data.baseline[0].base_line_entries[key].port_state ==
                "open" &&
              !res.data.baseline[0].base_line_entries[key].is_false_positive
            ) {
              var open_port_item = {
                ip: res.data.baseline[0].base_line_entries[key].ip,
                port: res.data.baseline[0].base_line_entries[key].port,
              };
              self.open_ports.push(open_port_item);
            }
          });
          console.log("open_ports", self.open_ports);
        }
      });
    },
    isIP: function (value) {
      // Regular expression to match IP address format
      const ipRegex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
      return ipRegex.test(value);
    },
    getExpiryBadgeVariant(expiryDate) {
      if (expiryDate) {
        const currentDate = moment();
        const expiryDateMoment = moment(expiryDate);
        if (currentDate < expiryDateMoment) {
          return "success"; // Show in success variant
        } else {
          return "danger"; // Show in danger variant
        }
      }
      return ""; // Return empty string if expiry date is not available
    },
    gotoAssests: function () {
      this.$router.push({ name: "Assets" });
    },
    openVerification: function () {
      this.$refs["modal-verify"].show();
    },
    callBack: function () {
      this.$refs["modal-verify"].hide();
      this.load();
    },
    printAssetOverview() {
      const assetOverview = document.getElementById("assetOverview");
      const printWindow = window.open("", "_blank");
      printWindow.document.write(
        "<html><head><title>Asset Overview</title></head><body>"
      );
      printWindow.document.write(assetOverview.innerHTML);
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
    },
    gotoUrl() {
      this.urlModal = true;
    },
    closeModal() {
      this.urlModal = false;
      this.loadAssetUrls();
    },
    closeEditModal() {
      this.editUrlModal = false;
      this.loadAssetUrls();
    },
    loadAssetUrls() {
      this.loadingUrl = true;
      this.open_ports = [];
      this.asset_id = parseInt(this.$route.params.id);
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/asset-url?asset_id=" +
          this.$route.params.id,
      };
      var self = this;
      this.$http(options).then((res) => {
        this.asset_url_list = res.data.results;
        this.loadingUrl = false;
      });
    },
    gotoDelete(url_id) {
      this.urlId = url_id;
      this.openDeleteModal = true;
    },
    deleteUrl() {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL + "asset/asset-url/" + this.urlId + "/",
      };
      var self = this;
      this.$http(options).then((res) => {
        this.openDeleteModal = false;
        this.loadAssetUrls();
        self.$toast({
          component: ToastificationContent,
          props: {
            title: "Deleted Successfully",
            icon: "EditIcon",
            variant: "success",
          },
        });
      });
    },
    gotoEditUrl(url_id) {
      this.urlId = url_id;
      this.editUrlModal = true;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.ports_sec {
  max-height: 200px;
  overflow-y: scroll;
}
</style>

<style>
.path-cl {
  max-width: 300px;
}
@media print {
  .no-print {
    display: none;
  }
}
</style>
