<template>
  <div>
    <!-- form -->
    <validation-observer ref="ScanScheduleForm">
      <b-form class="auth-login-form mt-2" @submit.prevent="scheduleScan">
        <!-- urls -->
        <b-form-group label-for="urls" v-if="!this.is_group">
          <template #label>
            <span>URLs</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
            <b-button
              class="ml-1"
              style="padding: 0.186rem 1rem !important"
              @click="gotoUrl()"
              variant="outline-success"
              size="sm"
              >+
            </b-button>
          </template>
          <validation-provider
            #default="{ errors }"
            name="url"
            rules="required"
          >
            <v-select
              v-model="urlName"
              label="text"
              placeholder="--Select URL--"
              :options="url_options"
              autocomplete
              style="width: 100%"
              :reduce="(urlName) => urlName.value"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Scanner-->
        <b-form-group label="Repeat By" label-for="Schedule">
          <template #label>
            <span>Repeat By</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="schedule_selected"
            rules="required"
          >
            <b-form-select
              v-model="schedule_selected"
              :options="schedule"
              :state="errors.length > 0 ? false : null"
            ></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <div v-if="schedule_selected === 5 || schedule_selected === 6">
          <div class="row">
            <div class="col-12">
              <b-form-group label="Date" label-for="Date">
                <flat-pickr
                  placeholder="--Select Date--"
                  style="width: 100%"
                  v-model="scanDate"
                  class="form-control"
                />
              </b-form-group>
            </div>

            <div class="col-4">
              <b-form-group label="Time" label-for="Time">
                <v-select
                  v-model="hours"
                  label="text"
                  placeholder="--Time --"
                  :options="hours_options"
                  autocomplete
                  style="width: 100%"
                  :reduce="(hours) => hours.value"
                >
                </v-select>
              </b-form-group>
            </div>
            <div class="col-8">
              <b-form-group label="Timezone" label-for="Timezone">
                <v-select
                  v-model="selectedTimeZone"
                  label="text"
                  placeholder="--Select Time Zone--"
                  :options="timeZones"
                  autocomplete
                  style="width: 100%"
                  :reduce="(selectedTimeZone) => selectedTimeZone.value"
                >
                </v-select>
              </b-form-group>
            </div>
          </div>
        </div>
        <b-form-group label="" label-for="is_authorised">
          <b-form-checkbox
            id="checkbox-1"
            v-model="is_authorised"
            name="is_authorised"
            value="true"
            unchecked-value="false"
          >
            Authorised Scan
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          label="Username"
          label-for="username"
          v-if="is_authorised === true || is_authorised === 'true'"
        >
          <template #label>
            <span>Username</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="username"
            rules="required"
          >
            <b-form-input
              id="username"
              :state="errors.length > 0 ? false : null"
              v-model="username"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Password"
          label-for="password"
          v-if="is_authorised === true || is_authorised === 'true'"
        >
          <template #label>
            <span>Password</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="password"
            rules="required"
          >
            <b-form-input
              id="password"
              :state="errors.length > 0 ? false : null"
              v-model="password"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Is scoped -->
        <b-form-group label="" label-for="is_scoped">
          <b-form-checkbox
            id="checkbox-2"
            v-model="is_scoped"
            name="is_scoped"
            value="true"
            unchecked-value="false"
          >
            Scoped Scan
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          label="Include"
          label-for="include"
          v-if="is_scoped === true || is_scoped === 'true'"
        >
          <b-form-input id="include" v-model="include"></b-form-input>
        </b-form-group>
        <b-form-group
          label="Exclude"
          label-for="exclude"
          v-if="is_scoped === true || is_scoped === 'true'"
        >
          <b-form-input id="exclude" v-model="exclude"></b-form-input>
        </b-form-group>
        <b-button
          type="submit"
          variant="primary"
          :disabled="isBtnDisable || schedule_selected === ''"
          size="sm"
        >
          <span v-if="isLoading"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else>Schedule</span>
        </b-button>
      </b-form>
    </validation-observer>
    <b-modal
      title="Add New URL"
      hide-footer
      no-close-on-backdrop
      size="lg"
      v-model="urlModal"
    >
      <CreateAssetUrl :assetId="asset_id" :closeModal="closeModal" />
    </b-modal>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BFormTextarea,
  BFormCheckbox,
  BSpinner,
  BFormSelectOption,
  BFormTimepicker,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { integer } from "vee-validate/dist/rules";
import flatPickr from "vue-flatpickr-component";
import moment from "moment-timezone";
import vSelect from "vue-select";
import CreateAssetUrl from "../components/asset_url/add.vue";
export default {
  data() {
    return {
      userId: localStorage.getItem("userid"),
      schedule_selected: "",
      customScanInputMeta: {},
      username: "",
      password: "",
      is_authorised: false,
      is_scoped: false,
      include: "",
      exclude: "",
      schedule: [
        { value: null, text: "--Select Schedule--" },
        { value: 4, text: "Continous Scan" },
        { value: 5, text: "Monthly" },
        { value: 6, text: "Quarterly" },
      ],
      isLoading: false,
      isBtnDisable: false,
      hours_options: [
        { value: 0, text: "0 to 1 hour" },
        { value: 1, text: "1 to 2 hours" },
        { value: 2, text: "2 to 3 hours" },
        { value: 3, text: "3 to 4 hours" },
        { value: 4, text: "4 to 5 hours" },
        { value: 5, text: "5 to 6 hours" },
        { value: 6, text: "6 to 7 hours" },
        { value: 7, text: "7 to 8 hours" },
        { value: 8, text: "8 to 9 hours" },
        { value: 9, text: "9 to 10 hours" },
        { value: 10, text: "10 to 11 hours" },
        { value: 11, text: "11 to 12 hours" },
        { value: 12, text: "12 to 13 hours" },
        { value: 13, text: "13 to 14 hours" },
        { value: 14, text: "14 to 15 hours" },
        { value: 15, text: "15 to 16 hours" },
        { value: 16, text: "16 to 17 hours" },
        { value: 17, text: "17 to 18 hours" },
        { value: 18, text: "18 to 19 hours" },
        { value: 19, text: "19 to 20 hours" },
        { value: 20, text: "20 to 21 hours" },
        { value: 21, text: "21 to 22 hours" },
        { value: 22, text: "22 to 23 hours" },
        { value: 23, text: "23 to 24 hours" },
      ],
      hours: "",
      hours_options: [
        { value: "01:00:00", text: "01:00" },
        { value: "02:00:00", text: "02:00" },
        { value: "03:00:00", text: "03:00" },
        { value: "04:00:00", text: "04:00" },
        { value: "05:00:00", text: "05:00" },
        { value: "06:00:00", text: "06:00" },
        { value: "07:00:00", text: "07:00" },
        { value: "08:00:00", text: "08:00" },
        { value: "09:00:00", text: "09:00" },
        { value: "10:00:00", text: "10:00" },
        { value: "11:00:00", text: "11:00" },
        { value: "12:00:00", text: "12:00" },
        { value: "13:00:00", text: "13:00" },
        { value: "14:00:00", text: "14:00" },
        { value: "15:00:00", text: "15:00" },
        { value: "16:00:00", text: "16:00" },
        { value: "17:00:00", text: "17:00" },
        { value: "18:00:00", text: "18:00" },
        { value: "19:00:00", text: "19:00" },
        { value: "20:00:00", text: "20:00" },
        { value: "21:00:00", text: "21:00" },
        { value: "22:00:00", text: "22:00" },
        { value: "23:00:00", text: "23:00" },
        { value: "00:00:00", text: "24:00" },
      ],
      scanDate: "",
      timeZone: "",
      timeZones: [],
      selectedTimeZone: null,
      disableMinutes: true,
      // frequency: "",
      // frequency_options: [
      //   { value: null, text: "--Select Schedule--" },
      //   { value: 1, text: "Once in a month" },
      //   { value: 3, text: "once in a three-month" },
      //   { value: 6, text: "once in a six-month" },
      //   { value: 12, text: "Once in a year" },
      // ],
      url_options: [],
      urlName: null,
      urlModal: false,
    };
  },
  props: {
    asset_id: {
      type: Number,
      required: true,
    },
    agent_type_id: {
      type: Number,
      required: true,
    },
    agent_type: {
      type: String,
      required: true,
    },
    callBack: {
      type: Function,
      required: true,
    },
    // If is_group is true, asset_id passed is actually group_id
    is_group: {
      type: Boolean,
      required: false,
    },
    asset_group_id: {
      type: Number,
      required: true,
    },
    UrlList: {
      type: Function,
      required: true,
    },
  },
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BFormCheckbox,
    BSpinner,
    flatPickr,
    BFormSelectOption,
    BFormTimepicker,
    vSelect,
    CreateAssetUrl,
  },
  created() {
    this.load();
    if (!this.is_group) {
      this.loadAssetUrls();
    }
    this.populateTimeZones();
    this.setDefaultTimeZone();
  },
  methods: {
    load: function () {},
    populateTimeZones() {
      this.timeZones = moment.tz
        .names()
        .filter((tz) => tz !== "Asia/Calcutta")
        .map((tz) => ({ text: tz, value: tz }));
      // this.timeZones = moment.tz.names();
    },
    setDefaultTimeZone() {
      const localTimeZone = moment.tz.guess();
      const updatedTimeZone =
        localTimeZone === "Asia/Calcutta" ? "Asia/Kolkata" : localTimeZone;
      this.selectedTimeZone = updatedTimeZone;
    },
    loadAssetUrls() {
      this.open_ports = [];
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/asset-url?asset_id=" +
          this.asset_id,
      };
      var self = this;
      this.$http(options).then((res) => {
        this.url_options = [];
        res.data.results.map(function (value, key) {
          let a = {
            value: res.data.results[key].url_id,
            text: res.data.results[key].url,
          };
          self.url_options.push(a);
        });
      });
    },
    scheduleScan: function () {
      // Open pop up
      this.isBtnDisable = true;
      this.isLoading = true;
      //this.$parent.$refs['modal-customize-meta'].hide()
      //{"ports_string": "0-1000,U:0-1000"}
      if (this.is_authorised) {
        this.customScanInputMeta["application_logins"] = {
          username: this.username,
          password: this.password,
        };
      }
      if (this.is_scoped && (this.include != "" || this.exclude != "")) {
        let scope = {};
        if (this.include != "") scope["include"] = { rule: this.include };
        if (this.exclude != "") scope["exclude"] = { rule: this.exclude };
        this.customScanInputMeta["scope"] = scope;
      }
      this.customScanInputMeta = JSON.stringify(this.customScanInputMeta);
      console.log(this.customScanInputMeta);
      let data = {
        agent_type: this.agent_type_id,
        // asset_id: this.asset_id,
        scan_created_by: this.userId,
        scan_input_meta: this.customScanInputMeta,
        is_scan_scheduled: 1,
        scan_schedule_type: this.schedule_selected,
      };
      if (this.schedule_selected === 5 || this.schedule_selected === 6) {
        data["scan_schedule_time"] = this.hours;
        (data["timezone"] = this.selectedTimeZone),
          (data["scan_schedule_date"] = this.scanDate);
        // data["scan_schedule_date_frequency"] = this.frequency;
      }
      if (this.is_group) {
        data["asset_group"] = this.asset_group_id;
        // data["url_id"] = null;
      } else {
        data["asset_id"] = this.asset_id;
        data["url_id"] = this.urlName;
      }

      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "scan/schedule/",
      };
      var self = this;
      this.$http(options)
        .then((res) => {
          if (res.data.schedule_id) {
            const callBackParams = "modal-schedule-scan-burp";
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Successfully added the scan request",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.isLoading = false;
            this.isBtnDisable = false;
            this.callBack(callBackParams);
            // this.load();
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.isBtnDisable = false;
        });
    },
    gotoUrl() {
      this.urlModal = true;
    },
    closeModal() {
      this.urlModal = false;
      this.loadAssetUrls();
      this.UrlList();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>