<template>
  <b-card>
    <div class="row m-2" v-for="ipr in ip_reputation_items" :key="ipr">  
          <div class="col-12">
            <!-- <p><b-badge variant="success"></b-badge> </p> -->
            <b-card-code class="border-primary">
                <div>
                    <p> <b>Fraud Score</b>: <span style="color:#7367f0">{{ipr.fraud_score}}</span></p>
                    <p class="" v-if="ipr.fraud_score >= 75">Advice : <b-badge variant="warning">suspicious</b-badge> - previous reputation issues or low risk proxy/VPN.</p>
                    <p class="" v-else-if="ipr.fraud_score >= 88">Advice :<b-badge variant="danger">high risk</b-badge> - recent abusive behavior over the past 24-48 hours.</p>
                    <p class="" v-else-if="ipr.fraud_score >= 90">Advice :<b-badge variant="danger">high risk</b-badge> - recent reputation issues like abuse associated with a name, email, phone number, payment method, etc.</p>
                </div>
                <div>
                    <p> <b>Abuse Velocity</b>: <span style="color:#7367f0">{{ipr.scan_result.abuse_velocity}}</span> </p>
                    <!-- <p v-if="ipr.scan_result.abuse_velocity = high">Advice : <b-badge variant="danger">high risk</b-badge> - previous reputation issues or low risk proxy/VPN.</p> -->
                    <!-- <p v-else-if="ipr.fraud_score >= 88">Advice :<b-badge variant="danger">high risk</b-badge> - recent abusive behavior over the past 24-48 hours.</p> -->
                    <p class="">Advice : How frequently the IP address is engaging in abuse across the IPQS threat network. Values can be "high", "medium", "low", or "none". Can be used in combination with the Fraud Score to identify bad behavior.</p>
                </div>
                 <div>
                    <p> <b>Country Code</b>: <span style="color:#7367f0">{{ipr.scan_result.country_code}}</span></p>
                    <p class="" >Advice : Two character country code of IP address or "N/A" if unknown</p>
                    <!-- <p class="" v-else-if="ipr.fraud_score >= 88">Advice :<b-badge variant="danger">high risk</b-badge> - recent abusive behavior over the past 24-48 hours.</p>
                    <p class="" v-else-if="ipr.fraud_score >= 90">Advice :<b-badge variant="danger">high risk</b-badge> - recent reputation issues like abuse associated with a name, email, phone number, payment method, etc.</p> -->
                </div>
                <div>
                    <p> <b>Region</b>: <span style="color:#7367f0">{{ipr.scan_result.region}}</span></p>
                    <p class="" >Advice : Region (state) of IP address if available or "N/A" if unknown.</p>
                </div>
                <div>
                    <p> <b>City</b>: <span style="color:#7367f0">{{ipr.scan_result.city}}</span></p>
                    <p class="" >Advice : Region (state) of IP address if available or "N/A" if unknown.</p>
                </div>
                <div>
                    <p> <b>Zip code</b>: <span style="color:#7367f0">{{ipr.scan_result.zip_code}}</span></p>
                    <p class="" >Advice : Postal code of IP address if available or "N/A" if unknown. IP addresses can relate to multiple postal codes in a city, so we recommend performing analysis of similar postal codes nearby.</p>
                </div>
                <div>
                    <p> <b>ISP</b>: <span style="color:#7367f0">{{ipr.scan_result.ISP}}</span></p>
                    <p class="" >Advice : ISP if one is known. Otherwise "N/A"</p>
                </div>
                <div>
                    <p> <b>ASN</b>: <span style="color:#7367f0">{{ipr.scan_result.ASN}}</span></p>
                    <p class="" >Advice : Autonomous System Number if one is known. Null if nonexistent.</p>
                </div>
                <div>
                    <p> <b>Organization</b>: <span style="color:#7367f0">{{ipr.scan_result.organization}}</span></p>
                    <p class="" >Advice : Organization if one is known. Can be parent company or sub company of the listed ISP. Otherwise "N/A".</p>
                </div>
                <div>
                    <p> <b>is_crawler</b>: <span style="color:#7367f0">{{ipr.scan_result.is_crawler}}</span></p>
                    <p class="" >Advice : Is this IP associated with being a confirmed crawler from a mainstream search engine such as Googlebot, Bingbot, Yandex, etc. based on hostname or IP address verification.</p>
                </div>
                <div>
                    <p> <b>Timezone</b>: <span style="color:#7367f0">{{ipr.scan_result.timezone}}</span></p>
                    <p class="" >Advice : Timezone of IP address if available or "N/A" if unknown.</p>
                </div>
                 <div>
                    <p> <b>Mobile</b>: <span style="color:#7367f0">{{ipr.scan_result.mobile}}</span></p>
                    <p class="" >Advice : s this user agent a mobile browser? (will always be false if the user agent is not passed in the API request)</p>
                </div>
                 <div>
                    <p> <b>Host</b>: <span style="color:#7367f0">{{ipr.scan_result.host}}</span></p>
                    <p class="" >Advice : Hostname of the IP address if one is available.</p>
                </div>
                 <div>
                    <p> <b>Proxy</b>: <span style="color:#7367f0">{{ipr.scan_result.proxy}}</span></p>
                    <p class="" >Advice : Is this IP address suspected to be a proxy? (SOCKS, Elite, Anonymous, VPN, Tor, etc.)</p>
                </div>
                 <div>
                    <p> <b>VPN</b>: <span style="color:#7367f0">{{ipr.scan_result.vpn}}</span></p>
                    <p class="" >Advice : Is this IP suspected of being a VPN connection? This can include data center ranges which can become active VPNs at any time. The "proxy" status will always be true when this value is true.</p>
                </div>
                <div>
                    <p> <b>TOR</b>: <span style="color:#7367f0">{{ipr.scan_result.timezone}}</span></p>
                    <p class="" >Advice : Is this IP suspected of being a TOR connection? This can include previously active TOR nodes and exits which can become active TOR exits at any time. The "proxy" status will always be true when this value is true.</p>
                </div>
                <div>
                    <p> <b>Active VPN</b>: <span style="color:#7367f0">{{ipr.scan_result.active_vpn}}</span></p>
                    <p class="" >Advice : Identifies active VPN connections used by popular VPN services and private VPN servers.</p>
                </div>
                <div>
                    <p> <b>Active TOR</b>: <span style="color:#7367f0">{{ipr.scan_result.active_tor}}</span></p>
                    <p class="" >Advice : Identifies active TOR exits on the TOR network.</p>
                </div>
                <div>
                    <p> <b>Recent Abuse</b>: <span style="color:#7367f0">{{ipr.scan_result.recent_abuse}}</span></p>
                    <p class="" >Advice : This value will indicate if there has been any recently verified abuse across our network for this IP address. Abuse could be a confirmed chargeback, compromised device, fake app install, or similar malicious behavior within the past few days.</p>
                </div>
                <div>
                    <p> <b>Bot Status</b>: <span style="color:#7367f0">{{ipr.scan_result.bot_status}}</span></p>
                    <p class="" >Advice :  Indicates if bots or non-human traffic has recently used this IP address to engage in automated fraudulent behavior. Provides stronger confidence that the IP address is suspicious.</p>
                </div>
                 <div>
                    <p> <b>Connection Type</b>: <span style="color:#7367f0">{{ipr.scan_result.connection_type}}</span></p>
                    <p class="" >Advice : Classification of the IP address connection type as "Residential", "Corporate", "Education", "Mobile", or "Data Center".</p>
                </div>
                 <div>
                    <p> <b>Abuse Velocity</b>: <span style="color:#7367f0">{{ipr.scan_result.abuse_velocity}}</span></p>
                    <p class="" >Advice : How frequently the IP address is engaging in abuse across the IPQS threat network. Values can be "high", "medium", "low", or "none". Can be used in combination with the Fraud Score to identify bad behavior.</p>
                </div>
                 <div>
                    <p> <b>Latitude</b>: <span style="color:#7367f0">{{ipr.scan_result.latitude}}</span></p>
                    <p class="" >Advice : Latitude of IP address if available or "N/A" if unknown.</p>
                </div>
                 <div>
                    <p> <b>Longitude</b>: <span style="color:#7367f0">{{ipr.scan_result.longitude}}</span></p>
                    <p class="" >Advice : Longitude of IP address if available or "N/A" if unknown.</p>
                </div>
                 <div>
                    <p> <b>Request ID</b>: <span style="color:#7367f0">{{ipr.scan_result.request_id}}</span></p>
                    <p class="" >Advice : A unique identifier for this request that can be used to lookup the request details or send a postback conversion notice.</p>
                </div>
           </b-card-code>
          </div>
    </div>
  </b-card>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BCard, BBadge } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BBadge,
    BCardCode,
  },
  data() {
    return {
      ip_reputation_items: [],
      organization_id: "",
    };
  },
  created() {
    this.load();
  },
  methods: {
    load: function () {
      this.asset_id = parseInt(this.$route.params.id);
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/org-asset/" +
          this.$route.params.id +
          "/",
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res.data, "email s ");
        // this.organization_id = res.data.assetOwner.profile.organization;
        this.ip_reputation_items = res.data.ip_reputation;
        // this.esecurtity_rows = res.data.emailsecuritydata.length;
      });
    },
  },
};
</script>
