<template>
  <b-col cols="12">
    <b-card-code class="border-primary" title="Email Security" no-body v-if="esecurtity_items.length">
      <!-- <button
          @click="addesecurtity()"
          type="button"
          class="btn ml-auto btn-primary m-1"
        >
          Add New
        </button> -->
      <div class="row m-2" v-for="es in esecurtity_items" :key="es">
        <div class="col-12">
          <p><b-badge variant="success">SPF</b-badge></p>
          <b-card-code class="border-primary">
            <p>Value : {{ es.spf.value }}</p>
            <p>Advice : {{ es.spf.advice }}</p>
          </b-card-code>
        </div>
        <div class="col-12">
          <p><b-badge variant="success">MX</b-badge></p>
          <b-card-code class="border-primary">
            <p>Value : {{ es.mx.value }}</p>
            <p>Advice : {{ es.mx.advice }}</p>
          </b-card-code>
        </div>
        <div class="col-12">
          <p><b-badge variant="success">DKIM</b-badge></p>
          <b-card-code class="border-primary">
            <p>Value : {{ es.dkim.value }}</p>
            <p>Advice : {{ es.dkim.advice }}</p>
          </b-card-code>
        </div>
        <div class="col-12">
          <p><b-badge variant="success">DMARC</b-badge></p>
          <b-card-code class="border-primary">
            <p>Value : {{ es.dmarc.value }}</p>
            <p>Advice : {{ es.dmarc.advice }}</p>
          </b-card-code>
        </div>
      </div>

      <!-- <b-table
        responsive
        id="EmailTable"
        :fields="esecurtity_fields"
        :items="esecurtity_items"
        :per-page="esecurtity_perPage"
        :current-page="esecurtity_currentPage"
        hover
      >
        <template #cell(index)="data">
          {{
            data.index + 1 + esecurtity_perPage * (esecurtity_currentPage - 1)
          }}
        </template>
        <template #cell(name)="data">
          <a class="text-primary" @click="showSidebar(data)">{{
            data.item.name
          }}</a>
        </template>
        <template #cell(severity)="data">
          <b-badge
            style="background-color: #991d28"
            v-if="data.item.severity == 'critical'"
            >Critical</b-badge
          >
          <b-badge variant="danger" v-else-if="data.item.severity == 'high'"
            >High</b-badge
          >
          <b-badge variant="warning" v-else-if="data.item.severity == 'medium'"
            >Medium</b-badge
          >
          <b-badge variant="primary" v-else-if="data.item.severity == 'low'"
            >Low</b-badge
          >
          <b-badge variant="success" v-else-if="data.item.severity == 'info'"
            >Info</b-badge
          >
          <b-badge variant="secondary" v-else>Unknown</b-badge>
        </template>
        <template #cell(status)="data">
          <b-badge variant="danger" v-if="data.item.status == 0">Open</b-badge>
          <b-badge variant="success" v-if="data.item.status == 1"
            >Closed</b-badge
          >
          <b-badge variant="warning" v-if="data.item.status == 2"
            >False Positive</b-badge
          >
          <b-badge variant="success" v-if="data.item.status == 3"
            >Accepted</b-badge
          >
          <b-badge variant="warning" v-if="data.item.status == 4"
            >Assigned</b-badge
          >
          <b-badge variant="warning" v-if="data.item.status == 5"
            >Mitigated</b-badge
          >
        </template>

        <template #cell(actions)="data">
          <b-dropdown size="sm" variant="outline-primary" text="Actions">
            <b-dropdown-item v-if="data.item.status == 0"
              >Mark as fixed</b-dropdown-item
            >
            <b-dropdown-item v-if="data.item.status == 1"
              >Re-open</b-dropdown-item
            >
            <b-dropdown-item v-if="data.item.status == 0"
              >Mark as False Positive</b-dropdown-item
            >
            <b-dropdown-item>Mitigate</b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>Delete</b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <b-pagination
        v-model="esecurtity_currentPage"
        :total-rows="esecurtity_rows"
        :per-page="esecurtity_perPage"
        :align="pagination_pos"
        aria-controls="EmailTable"
      /> -->
      <!-- Right Sidebar starts -->
      <!-- <b-sidebar
          id="sidebar-task-handler"
          sidebar-class="sidebar-lg"
          bg-variant="white"
          shadow
          backdrop
          no-header
          right
        >
          

          <esecurtityDetail
            :esecurtity="esecurtity"
            :callBack="load"
            :closeSidebar="closeSidebar"
            :asset_id="asset_id"
            :organization="parseInt(organization_id)"
          />
        </b-sidebar> -->
    </b-card-code>
    <div v-else>
      <div
        class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
      >
        <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
        <h3 class="font-weight-bolder">No Data Found</h3>
      </div>
    </div>
  </b-col>
</template>



<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BSidebar,
  VBToggle,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
// import esecurtityDetail from "../esecurtity_details.vue";
import moment from "moment";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    // VBToggle,
    // esecurtityDetail,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      pagination_pos: "center",
      organization_id: "",
      asset_id: "",

      // Scanner attachment modal
      task_title: "",
      polling: null,

      esecurtity: {},
      esecurtity_items: [],
      esecurtity_currentPage: 1,
      esecurtity_perPage: 10,
      esecurtity_rows: 0,
      esecurtity_fields: [
        { key: "index", label: "#" },
        // { key: "vuln_id", label: "Vuln Id" },
        // { key: "name", label: "Name" },
        // { key: "severity", label: "Severity" },
        // { key: "source", label: "Source" },
        // { key: "path", label: "Path", tdClass: "path-cl" },
        // { key: "status", label: "Status" },
        // { key: "asset_id", label: "Asset ID" },
        { key: "spf", label: "SPF" },
        { key: "mx", label: "MX" },
        { key: "dkim", label: "DKIM" },
        { key: "dmarc", label: "DMARC" },
      ],
    };
  },
  created() {
    this.load();
  },
  methods: {
    // addVuln: function () {
    //   this.$router.push({ name: "Add Vulnerability" });
    // },

    load: function () {
      this.asset_id = parseInt(this.$route.params.id);
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/org-asset/" +
          this.$route.params.id +
          "/",
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res.data, "email s ");
        // this.organization_id = res.data.assetOwner.profile.organization;
        this.esecurtity_items = res.data.emailsecuritydata;
        this.esecurtity_rows = res.data.emailsecuritydata.length;
      });
      //  res.data.map(function (value, key) {
      //       let org = {
      //         value: res.data[key].org_id,
      //         text: res.data[key].org_name,
      //       };
      //       self.organizations.push(org);
      //     });

      // this.loadScans();
      // this.loadSchedules();
    },
    // showSidebar: function (data) {
    //   this.vuln = data.item;
    //   console.log("vuln", data);
    //   // this.$refs['taskSideBar'].show();
    //   this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
    //   // this.isTaskHandlerSidebarActive = true
    // },
    // closeSidebar: function () {
    //   this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
    // },
  },
  // reference: https://renatello.com/vue-js-polling-using-setinterval/
};
</script>

<style lang="scss" scoped>
</style>
