<template>
  <b-col cols="12">
    <b-card no-body>
      <div class="d-flex justify-content-between m-1">
        <h4>Assigned Users</h4>
        <b-button  v-if="items.length" variant="success" size="sm" @click="gotoAssignUserModal()"
          >Assign Asset</b-button
        >
      </div>
      <div v-if="items.length">
        <b-table
          responsive
          id="userTable"
          :fields="fields"
          :items="items"
          :per-page="perPage"
          :current-page="currentPage"
          hover
        >
          <template #cell(index)="data">
            {{ data.index + 1 + perPage * (currentPage - 1) }}
          </template>
          <template #cell(full_name)="data">
            <div
              class="d-flex justify-content-between"
              style="white-space: nowrap; overflow: hidden"
            >
              <span style="text-overflow: ellipsis" class="text-capitalize">{{
                data.item.user_id.full_name
              }}</span>
            </div>
          </template>
          <template #cell(actions)="data">
            <b-button
              @click="
                gotoDelete(data.item.user_id.id, data.item.user_id.full_name)
              "
              type="button"
              variant="outline-danger"
              style="position: relative"
              size="sm"
            >
              Remove
            </b-button>
          </template>
        </b-table>
      </div>

      <div v-else>
        <div
          class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
        >
          <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
          <h3 class="font-weight-bolder">No Data Found</h3>
          <p>
            Navigate to Assign Users,
            <span
              class="font-weight-bold text-success cursor-pointer"
              @click="gotoAssignUserModal()"
              >Click here</span
            >
          </p>
        </div>
      </div>
      <b-modal
        hide-footer
        title="Assign Asset"
        v-model="openAssignUserModal"
        size="lg"
        no-close-on-backdrop
      >
        <AssignAsset
          :asset_id="[assetNameAndId]"
          :parentCallBack="closeModal"
          :organization="org_id"
        />
      </b-modal>
      <b-modal
        id="modal-danger"
        hide-footer
        modal-class="modal-danger"
        centered
        title="Remove.. !"
        v-model="openAssigneeDeleteModal"
        size="lg"
      >
        <b-card-text>
          Do you really want to Remove
          <span class="text-primary text-capitalize"
            >" {{ assignee_name }} "</span
          >
          ?
        </b-card-text>
        <div class="d-flex justify-content-end">
          <b-button @click="deleteAssignee()" variant="danger" size="sm">
            Remove
          </b-button>
        </div>
      </b-modal>
    </b-card>
  </b-col>
</template>


<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BSidebar,
  VBToggle,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import moment from "moment";
import AssignAsset from "../../components/AssignAsset.vue";
export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    AssignAsset,
    // VBToggle,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      asset_id: "",
      organization_id: "",
      pagination_pos: "center",
      task_title: "",
      org_id: this.$store.state.app.org_id,
      items: [],
      currentPage: 1,
      perPage: 10,
      baseline_rows: 0,
      fields: [
        { key: "index", label: "#" },
        { key: "full_name", label: "Full Name" },
        { key: "created_at", label: "Assigned Date" },
        { key: "actions", label: "Actions" },
      ],
      openAssigneeDeleteModal: false,
      assignee_id: null,
      assignee_name: "",
      openAssignUserModal: false,
    };
  },
  props: {
    asset_id: {
      type: Number,
      required: true,
    },
    asset: {
      type: Array,
      required: true,
    },
    organization: {
      type: Number,
      required: true,
    },
    assetNameAndId: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.load();
  },
  methods: {
    load: function () {
      //   this.asset_id = parseInt(this.$route.params.id);
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/org-asset/" +
          this.asset_id +
          "/asset-assigned-users",
      };
      var self = this;
      this.$http(options).then((res) => {
        res.data.results.forEach((item) => {
          item.created_at = moment(item.created_at).format("DD-MMM-YY");
        });
        this.items = res.data.results;
      });
    },
    gotoAssignUserModal() {
      this.openAssignUserModal = true;
    },
    closeModal() {
      this.openAssignUserModal = false;
      this.load();
    },
    gotoDelete(id, full_name) {
      this.openAssigneeDeleteModal = true;
      this.assignee_id = id;
      this.assignee_name = full_name;
    },
    deleteAssignee: function () {
      let data = {
        user_id: this.assignee_id,
        assets: this.asset,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "asset/remove-assigned-assets/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data.message) {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "TrashIcon",
              variant: "success",
            },
          });
          this.openAssigneeDeleteModal = false;
          this.load();
        }
      });
    },
  },
  // reference: https://renatello.com/vue-js-polling-using-setinterval/
};
</script>

<style scoped>
::v-deep .dropdown-menu {
  max-height: 100px;
  overflow-y: auto;
}
</style>
