<template>
  <b-col cols="12">
    <b-card-code>
      <!-- {{ items }} -->
      <b-row>
        <b-col lg="12">
          <div>
            <h4 class="text-left ml-2 mb-1">Vulnerabilities</h4>
          </div>
          <b-row class="ml-1 mr-1">
            <b-col
              ><div
                class="border-purple rounded text-center bg-blue-grad bg-purple cursor-pointer custom-card"
                @click="
                  $router.push({
                    path: '/assets/vulnerabilities',
                    query: {
                      severity: 'critical',
                      status: [0, 3, 5],
                      asset_id: asset_id,
                      tabIndex: 1,
                    },
                  })
                "
              >
                <span class="h5 text-purple">
                  {{ items.vuln_data.critical_vulns }}</span
                ><br /><span class="text-purple h6">Critical</span>
              </div></b-col
            >
            <b-col
              ><div
                class="border-danger rounded text-center bg-light-danger bg-blue-grad cursor-pointer custom-card"
                @click="
                  $router.push({
                    path: '/assets/vulnerabilities',
                    query: {
                      severity: 'high',
                      status: [0, 3, 5],
                      asset_id: asset_id,
                      tabIndex: 1,
                    },
                  })
                "
              >
                <span class="h5 text-danger">
                  {{ items.vuln_data.high_vulns }}</span
                ><br /><span class="text-danger h6">High</span>
              </div></b-col
            >
            <b-col
              ><div
                class="border-warning rounded text-center bg-blue-grad bg-light-warning cursor-pointer custom-card"
                @click="
                  $router.push({
                    path: '/assets/vulnerabilities',
                    query: {
                      severity: 'medium',
                      status: [0, 3, 5],
                      asset_id: asset_id,
                      tabIndex: 1,
                    },
                  })
                "
              >
                <span class="h5 text-warning">
                  {{ items.vuln_data.medium_vulns }}</span
                ><br /><span class="text-warning h6">Medium</span>
              </div></b-col
            >
            <b-col
              ><div
                class="border-success rounded text-center bg-blue-grad bg-light-success cursor-pointer custom-card"
                @click="
                  $router.push({
                    path: '/assets/vulnerabilities',
                    query: {
                      severity: 'low',
                      status: [0, 3, 5],
                      asset_id: asset_id,
                      tabIndex: 1,
                    },
                  })
                "
              >
                <span class="h5 text-success">
                  {{ items.vuln_data.low_vulns }}</span
                ><br /><span class="text-success h6">Low</span>
              </div></b-col
            >
            <b-col
              ><div
                class="border-info rounded text-center bg-blue-grad bg-light-info cursor-pointer custom-card"
                @click="
                  $router.push({
                    path: '/assets/vulnerabilities',
                    query: {
                      severity: 'info',
                      status: [0, 3, 5],
                      asset_id: asset_id,
                      tabIndex: 1,
                    },
                  })
                "
              >
                <span class="h5 text-info">
                  {{ items.vuln_data.info_vulns }}</span
                ><br /><span class="text-info h6">Info</span>
              </div></b-col
            >
          </b-row>
        </b-col>
        <b-col lg="4" class="border-right my-1">
          <VulnOverview :values="vuln_values" />
        </b-col>
        <b-col lg="4" class="border-right my-1">
          <TaskData :values="task_values" />
        </b-col>
        <b-col lg="4" class="my-1 border-right">
          <UpcomingScans :upcomingScans="upcomingScans" :scan_rows="scan_rows"/>
        </b-col>
        <b-col lg="4" class="my-1 d-flex align-items-center">
          <b-row>
            <b-col lg="12">
              <b-row>
                <b-col lg="12"
                  ><div
                    class="border-primary rounded text-center bg-blue-grad mb-1 mt-1 cursor-pointer custom-card"
                    @click="
                      $router.push({
                        path: '/scans',
                        query: { status: 2 },
                      })
                    "
                  >
                    <span class="h5 text-primary">
                      {{ items.scan_data.completed_scans }}</span
                    ><br /><span class="text-primary h6">Completed Scans</span>
                  </div></b-col
                >
                <b-col lg="12"
                  ><div
                    class="border-primary rounded text-center bg-blue-grad mb-1 cursor-pointer custom-card"
                    @click="
                      $router.push({
                        path: '/scans',
                        query: { status: 1 },
                      })
                    "
                  >
                    <span class="h5 text-primary">
                      {{ items.scan_data.active_scan }}</span
                    ><br /><span class="text-primary h6">Active Scans</span>
                  </div></b-col
                >
                <b-col lg="12"
                  ><div
                    class="border-primary rounded text-center bg-blue-grad mb-1 cursor-pointer custom-card"
                    @click="
                      $router.push({
                        path: '/schedules',
                      })
                    "
                  >
                    <span class="h5 text-primary">
                      {{ items.scan_data.active_schedules }}</span
                    ><br /><span class="text-primary h6">Active Schedules</span>
                  </div></b-col
                >
                <b-col lg="12"
                  ><div
                    class="border-primary rounded text-center bg-blue-grad mb-1"
                  >
                    <span class="h5 text-primary">
                      {{ items.insecure_port_data.insecure_port_count }}</span
                    ><br /><span class="text-primary h6"
                      >Insecure Port Count</span
                    >
                  </div></b-col
                >
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="8" class="my-1 border-left">
          <InsecurePort class="my-1" :insecureportList="insecureportList" />
        </b-col>
      </b-row>
      <b-row>
        <!-- <b-col lg="12">
          <div class="my-1">
            <h4 class="text-primary text-left">Scans</h4>
          </div>
          <b-row>
            <b-col
              ><div class="border-primary rounded text-center bg-blue-grad">
                <span class="h5 text-primary">
                  {{ items.scan_data.completed_scans }}</span
                ><br /><span class="text-primary h6">Completed</span>
              </div></b-col
            >
            <b-col
              ><div class="border-primary rounded text-center bg-blue-grad">
                <span class="h5 text-primary">
                  {{ items.scan_data.active_scan }}</span
                ><br /><span class="text-primary h6">Active Scan</span>
              </div></b-col
            >
            <b-col
              ><div class="border-primary rounded text-center bg-blue-grad">
                <span class="h5 text-primary">
                  {{ items.scan_data.active_schedules }}</span
                ><br /><span class="text-primary h6">Active Schedules</span>
              </div></b-col
            >
          </b-row>
        </b-col> -->
        <!-- <b-col lg="12">
          <div class="my-1">
            <h4 class="text-primary text-left">Tasks</h4>
          </div>
          <b-row>
            <b-col
              ><div class="border-primary rounded text-center bg-blue-grad">
                <span class="h5 text-primary">
                  {{ items.task_data.total_tasks }}</span
                ><br /><span class="text-primary h6">Total</span>
              </div></b-col
            >
            <b-col
              ><div class="border-primary rounded text-center bg-blue-grad">
                <span class="h5 text-primary">
                  {{ items.task_data.open_tasks }}</span
                ><br /><span class="text-primary h6">Open</span>
              </div></b-col
            >
            <b-col
              ><div class="border-primary rounded text-center bg-blue-grad">
                <span class="h5 text-primary">
                  {{ items.task_data.in_progress_tasks }}</span
                ><br /><span class="text-primary h6">In Progress</span>
              </div></b-col
            >
            <b-col
              ><div class="border-primary rounded text-center bg-blue-grad">
                <span class="h5 text-primary">
                  {{ items.task_data.completed_tasks }}</span
                ><br /><span class="text-primary h6">Completed</span>
              </div></b-col
            >
          </b-row>
        </b-col> -->
      </b-row>
    </b-card-code>
  </b-col>
</template>



<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BSidebar,
  VBToggle,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
// import VulnOverview from "../../../Dashboard/VulnOverview.vue";
import VulnOverview from "./AssetOverviewComponents/vulnOverviewAsset.vue";
import UpcomingScans from "./AssetOverviewComponents/upcomingScanAsset.vue";
import TaskData from "./AssetOverviewComponents/taskGraph.vue";
import InsecurePort from "./AssetOverviewComponents/insecureport.vue";
import moment from "moment";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    VulnOverview,
    UpcomingScans,
    TaskData,
    InsecurePort,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      pagination_pos: "center",
      organization_id: this.$store.state.app.org_id,
      asset_id: "",

      items: [],
      vuln_values: [],
      task_values: [],
      upcomingScans: [],
      insecureportList: [],
      overviewList: false,
    };
  },
  props: {
    overviewList: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    overviewList(newVal) {
      if (newVal) {
        this.load();
      }
    },
  },
  mounted() {
    if (this.overviewList) {
      this.load();
    }
  },
  methods: {
    load: function () {
      this.asset_id = parseInt(this.$route.params.id);
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/org-asset/" +
          this.$route.params.id +
          "/asset-overview",
      };
      var self = this;
      this.$http(options).then((res) => {
        this.items = res.data;
        this.upcomingScans = res.data.scan_data.upcoming_scans;
        this.scan_rows = res.data.scan_data.upcoming_scans.length;
        this.insecureportList = res.data.insecure_port_data.insecure_port_vulns;
        this.vuln_values = [
          self.items.vuln_data.low_vulns,
          self.items.vuln_data.medium_vulns,
          self.items.vuln_data.high_vulns,
          self.items.vuln_data.critical_vulns,
        ];
        this.task_values = [
          // self.items.task_data.total_tasks,
          self.items.task_data.open_tasks,
          self.items.task_data.completed_tasks,
          self.items.task_data.in_progress_tasks,
        ];
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-purple {
  background: rgb(160 32 240 / 22%) !important;
}
.text-purple {
  color: #a020f0 !important;
}
.border-purple {
  border: 1px solid #a020f0 !important;
}
.bg-blue-grad {
  /* background: linear-gradient(to bottom right,#825cf9,#8192d3); */
  color: #ffff !important;
  padding: 0.5rem;
}
.custom-card {
  transition: all 0.2s linear;
}

.custom-card:hover {
  transform: scale(1.05);
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}
</style>
