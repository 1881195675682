<template>
  <div>
    <!-- form -->
    <validation-observer ref="ScanNessusForm">
      <b-form class="auth-login-form mt-2" @submit.prevent="scanNessus">
        <!-- Scanner-->
        <b-form-group label="Policy Scope" label-for="Policy Scope">
          <template #label>
            <span>Policy Scope</span>
            <!-- <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            > -->
          </template>
          <validation-provider
            #default="{ errors }"
            name="Policy Scope"
            rules="required"
          >
            <b-form-select
              v-model="policyScope"
              :options="policyScopeOptions"
              @change="load()"
            ></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Select Policy" label-for="policy">
          <template #label>
            <span>Select Policy</span>
            <!-- <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            > -->
          </template>
          <validation-provider
            #default="{ errors }"
            name="Policy"
            rules="required"
          >
            <v-select
              v-model="nessusPolicy"
              label="text"
              placeholder="--Select Policy--"
              :options="policy_filter_options"
              autocomplete
              style="width: 100%"
              :reduce="(nessusPolicy) => nessusPolicy.value"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- <b-form-group label-for="urls">
          <template #label>
            <span>URLs</span>
            <b-button
              class="ml-1"
              style="padding: 0.186rem 1rem !important"
              @click="gotoUrl()"
              variant="outline-success"
              size="sm"
              >+
            </b-button>
          </template>
          <validation-provider
            #default="{ errors }"
            name="url"
            rules="required"
          >
            <v-select
              v-model="urlName"
              label="text"
              placeholder="--Select URL--"
              :options="url_options"
              autocomplete
              style="width: 100%"
              :reduce="(urlName) => urlName.value"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group> -->

        <b-button
          type="submit"
          variant="primary"
          :disabled="isBtnDisable"
          size="sm"
        >
          <span v-if="isLoading"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else>Scan</span>
        </b-button>
      </b-form>
    </validation-observer>
    <b-modal
      title="Add New URL"
      hide-footer
      no-close-on-backdrop
      size="lg"
      v-model="urlModal"
    >
      <CreateAssetUrl :assetId="asset_id" :closeModal="closeModal" />
    </b-modal>
  </div>
</template>
<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BFormTextarea,
  BSpinner,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { integer } from "vee-validate/dist/rules";
import CreateAssetUrl from "../components/asset_url/add.vue";
export default {
  data() {
    return {
      userId: localStorage.getItem("userid"),
      schedule_selected: "",
      isLoading: false,
      isBtnDisable: false,
      nessusPolicy: null,
      policy_filter_options: [],
      policyScope: "public",
      policyScopeOptions: [
        { value: "public", text: "Public" },
        { value: "internal", text: "Internal" },
      ],
      url_options: [],
      urlName: null,
      urlModal: false,
    };
  },
  props: {
    asset_id: {
      type: Number,
      required: true,
    },
    agent_type_id: {
      type: Number,
      required: true,
    },
    agent_type: {
      type: String,
      required: true,
    },
    callBack: {
      type: Function,
      required: true,
    },
    is_group: {
      type: Boolean,
      required: false,
    },
    asset_group_id: {
      type: Number,
      required: true,
    },
  },
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BSpinner,
    vSelect,
    CreateAssetUrl,
  },
  created() {
    this.load();
    if (!this.is_group) {
      this.loadAssetUrls();
    }
  },
  methods: {
    load: function () {
      this.nessusPolicy = null;
      this.policy_filter_options = [];
      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "scan/nessus-policy?policy_scope=" +
          this.policyScope,
      };
      var self = this;
      this.$http(a_options).then((res) => {
        res.data.results.map(function (value, key) {
          let a = {
            value: res.data.results[key].id,
            text: res.data.results[key].policy_name,
          };
          self.policy_filter_options.push(a);
        });
      });
    },
    loadAssetUrls() {
      this.open_ports = [];
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/asset-url?asset_id=" +
          this.asset_id,
      };
      var self = this;
      this.$http(options).then((res) => {
        res.data.results.map(function (value, key) {
          let a = {
            value: res.data.results[key].url_id,
            text: res.data.results[key].url,
          };
          self.url_options.push(a);
        });
      });
    },
    scanNessus: function () {
      this.isBtnDisable = true;
      this.isLoading = true;
      let data = {
        agent_type: this.agent_type_id,
        // asset_id: this.asset_id,
        scan_created_by: this.userId,
        nessus_policy: this.nessusPolicy,
      };
      if (this.is_group) data["asset_group"] = this.asset_group_id;
      else data["asset_id"] = this.asset_id;
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "scan/scan/",
      };
      var self = this;
      this.$http(options)
        .then((res) => {
          const callBackParams = "modal-nessus";
          if (res.data.scan_id) {
            this.isLoading = false;
            this.isBtnDisable = false;
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Successfully added the scan request",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.callBack(callBackParams);
          } else {
            this.isLoading = false;
            this.isBtnDisable = false;
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Trouble while processing the scan request",
                icon: "EditIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.isBtnDisable = false;
        });
    },
    gotoUrl() {
      this.urlModal = true;
    },
    closeModal() {
      this.urlModal = false;
      this.loadAssetUrls();
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>