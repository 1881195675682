<template>
  <b-col cols="12">
    <b-card-code title="Vulnerabilities" no-body>
      <b-button
        @click="addVuln()"
        type="button"
        variant="primary"
        class="ml-auto m-1"
        size="sm"
      >
        Add New
      </b-button>
      <b-table
        responsive
        id="vulnTable"
        :fields="vuln_fields"
        :items="vuln_items"
        :per-page="vuln_perPage"
        :current-page="vuln_currentPage"
        hover
      >
        <template v-slot:head(cve_meta_data)>
          <div
            class="d-flex justify-content-between"
            style="white-space: nowrap; overflow: hidden"
          >
            <span style="text-overflow: ellipsis">Remediation Priority</span>
          </div>
        </template>
        <template v-slot:head(is_assigned)>
          <div
            class="d-flex justify-content-between"
            style="white-space: nowrap; overflow: hidden"
          >
            <span style="text-overflow: ellipsis">Assigned For Retest</span>
          </div>
        </template>
        <template #cell(index)="data">
          {{ data.index + 1 + vuln_perPage * (vuln_currentPage - 1) }}
        </template>
        <template #cell(name)="data">
          <a class="text-truncate" @click="showSidebar(data)">
            <del
              v-if="data.item.status == 1"
              v-html="breakTextIntoLines(data.item.name)"
            >
            </del>
            <span v-else v-html="breakTextIntoLines(data.item.name)"></span>
          </a>
        </template>
        <template #cell(cve_meta_data)="data">
          <b-badge
            style="background-color: #991d28"
            v-if="data.item.cve_meta_data == 1"
            >Critical</b-badge
          >
          <b-badge variant="danger" v-if="data.item.cve_meta_data == 2"
            >High</b-badge
          >
          <b-badge variant="warning" v-if="data.item.cve_meta_data == 3"
            >Medium</b-badge
          >
          <b-badge variant="success" v-if="data.item.cve_meta_data == 4"
            >Low</b-badge
          >
          <b-badge variant="info" v-if="data.item.cve_meta_data == 5"
            >Very Low</b-badge
          >
          <b-badge variant="light-danger" v-if="data.item.cve_meta_data == null"
            >NA</b-badge
          >
          <div class="mt-1">
            <span class="d-flex" style="front-size: 2px">
              <span class="mr-1" style="font-size: 10px">CISA KEV:</span>
              <b-badge
                variant="success"
                style="font-size: 8px"
                v-if="data.item.cisa_kev == true"
                >Yes</b-badge
              >
              <b-badge
                variant="danger"
                v-if="data.item.cisa_kev == false"
                style="font-size: 8px"
                >No</b-badge
              >
            </span>
            <span class="d-flex">
              <span style="font-size: 10px" class="mr-1">EPSS:</span>
              <span style="font-size: 10px">{{ data.item.epss }}</span>
            </span>
          </div>
        </template>
        <template #cell(severity)="data">
          <b-badge
            style="background-color: #991d28"
            v-if="data.item.severity == 'critical'"
            >Critical</b-badge
          >
          <b-badge variant="danger" v-else-if="data.item.severity == 'high'"
            >High</b-badge
          >
          <b-badge variant="warning" v-else-if="data.item.severity == 'medium'"
            >Medium</b-badge
          >
          <b-badge variant="primary" v-else-if="data.item.severity == 'low'"
            >Low</b-badge
          >
          <b-badge variant="success" v-else-if="data.item.severity == 'info'"
            >Info</b-badge
          >
          <b-badge variant="secondary" v-else>Unknown</b-badge>
        </template>
        <template #cell(status)="data">
          <b-badge variant="danger" v-if="data.item.status == 0">Open</b-badge>
          <b-badge variant="success" v-if="data.item.status == 1"
            >Closed</b-badge
          >
          <b-badge variant="warning" v-if="data.item.status == 2"
            >False Positive</b-badge
          >
          <b-badge variant="success" v-if="data.item.status == 3"
            >Accepted</b-badge
          >
          <b-badge variant="warning" v-if="data.item.status == 4"
            >Assigned</b-badge
          >
          <b-badge variant="warning" v-if="data.item.status == 5"
            >Mitigated</b-badge
          >
        </template>
        <template #cell(is_verified)="data">
          <b-avatar v-if="data.item.is_verified" variant="success">
            <feather-icon icon="ThumbsUpIcon" />
          </b-avatar>
          <b-avatar v-else variant="danger">
            <feather-icon icon="ThumbsDownIcon" />
          </b-avatar>
        </template>
        <template v-slot:cell(is_assigned)="data">
          <div class="d-flex justify-content-center">
            <a class="text-primary" @click="showSidebarTask(data)">
              <b-badge variant="success" v-if="data.item.is_assigned == true"
                >Yes</b-badge
              >
              <b-badge variant="danger" v-else>No</b-badge>
            </a>
          </div>
        </template>

        <template #cell(actions)="data">
          <b-dropdown size="sm" variant="outline-primary" text="Actions">
            <b-dropdown-item v-if="data.item.status == 0"
              >Mark as fixed</b-dropdown-item
            >
            <b-dropdown-item v-if="data.item.status == 1"
              >Re-open</b-dropdown-item
            >
            <b-dropdown-item v-if="data.item.status == 0"
              >Mark as False Positive</b-dropdown-item
            >
            <b-dropdown-item>Mitigate</b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>Delete</b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <b-pagination
        v-model="vuln_currentPage"
        :total-rows="vuln_rows"
        :per-page="vuln_perPage"
        :align="pagination_pos"
        aria-controls="vulnTable"
      />
      <!-- Right Sidebar starts -->
      <b-sidebar
        id="sidebar-task-handler"
        sidebar-class="sidebar-lg"
        bg-variant="white"
        shadow
        backdrop
        no-header
        right
      >
        <!-- Header -->

        <VulnDetail
          :vuln="vuln"
          :callBack="load"
          :closeSidebar="closeSidebar"
          :asset_id="asset_id"
          :organization="parseInt(organization_id)"
          :assetOwner="assetOwner"
        />
      </b-sidebar>
    </b-card-code>
  </b-col>
</template>



<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BSidebar,
  VBToggle,
  BAvatar,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VulnDetail from "../vuln_details.vue";
import moment from "moment";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    // VBToggle,
    VulnDetail,
    BAvatar,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      pagination_pos: "center",
      organization_id: this.$store.state.app.org_id,
      asset_id: "",

      // Scanner attachment modal
      task_title: "",
      polling: null,

      vuln: {},
      vuln_items: [],
      vuln_currentPage: 1,
      vuln_perPage: 10,
      vuln_rows: 0,
      vuln_fields: [
        { key: "index", label: "#" },
        // { key: "vuln_id", label: "Vuln Id" },
        { key: "name", label: "Name" },
        { key: "cve_meta_data", label: "Remediation Priority" },
        { key: "severity", label: "Severity" },
        { key: "source", label: "Source" },
        { key: "path", label: "Path", tdClass: "path-cl" },
        { key: "status", label: "Status" },
        { key: "is_verified", label: "Verified" },
        { key: "is_assigned", label: "Assigned" },
      ],
      expandedItems: [],
      assetOwner: null,
    };
  },
  created() {
    this.load();
  },
  methods: {
    addVuln: function () {
      this.$router.push({ name: "Add Vulnerability" });
    },
    breakTextIntoLines(text) {
      const maxCharacters = 30;
      const words = text.split(/\s+/);
      const lines = [];

      let currentLine = "";

      for (const word of words) {
        if ((currentLine + word).length <= maxCharacters) {
          currentLine += (currentLine ? " " : "") + word;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      }
      if (currentLine) {
        lines.push(currentLine);
      }
      return lines.join("<br>");
    },
    toggleExpand(data) {
      const index = this.expandedItems.indexOf(data.item.id);
      if (index === -1) {
        this.expandedItems.push(data.item.id);
      } else {
        this.expandedItems.splice(index, 1);
      }
    },
    isExpanded(itemId) {
      return this.expandedItems.includes(itemId);
    },
    truncateName(name) {
      if (name.length > 30) {
        return name.slice(0, 30);
      }
      return name;
    },
    load: function () {
      this.asset_id = parseInt(this.$route.params.id);
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/org-asset/" +
          this.$route.params.id +
          "/",
      };
      var self = this;
      this.$http(options).then((res) => {
        // this.organization_id = res.data.assetOwner.profile.organization;
        this.assetOwner = res.data.asset_owner_id;
        this.vuln_items = res.data.vulnerabilities;
        this.vuln_rows = res.data.vulnerabilities.length;
      });
      // this.loadScans();
      // this.loadSchedules();
    },
    showSidebar: function (data) {
      this.vuln = data.item;
      // this.asset_id = data.item.asset_id;
      console.log("vuln", data);
      // this.$refs['taskSideBar'].show();
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
      // this.isTaskHandlerSidebarActive = true
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
    },
  },
  // reference: https://renatello.com/vue-js-polling-using-setinterval/
};
</script>

<style lang="scss" scoped>
</style>
