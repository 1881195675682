<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card-code title="Scan Asset" no-body class="border-primary p-0">
        <!-- conf_fields -->
        <b-table
          responsive
          id="confTable"
          :fields="conf_fields"
          :items="filteredConfItems"
          hover
        >
          <template v-slot:head(actions)>
            <div
              class="d-flex justify-content-center"
              style="white-space: nowrap; overflow: hidden"
            >
              <span style="text-overflow: ellipsis">Actions</span>
            </div>
          </template>
          <template #cell(description)="data">
            <span v-if="data.item.description">
              <span v-html="breakTextIntoLines(data.item.description)"></span>
            </span>
          </template>
          <template #cell(actions)="data">
            <div
              class="d-flex justify-content-center"
              style="white-space: nowrap; overflow: hidden"
            >
              <!-- scan button -->
              <span
                style="text-overflow: ellipsis"
                v-if="data.item.is_active == false"
                v-b-tooltip.hover.v-warning
                title="Upgrade your Plan"
              >
                <b-button
                  size="sm"
                  variant="primary"
                  @click="
                    openNormalScanModal(
                      data.item.agent_type_id,
                      data.item.agent_type_code
                    )
                  "
                  type="button"
                  class="btn btn-primary mr-1"
                  style="position: relative"
                  :disabled="data.item.is_active == false"
                >
                  <span
                    v-if="isloading && data.item.agent_type_id == agent_type_id"
                    ><b-spinner type="border" small></b-spinner> Please
                    wait</span
                  >
                  <span v-else>Scan Now</span>
                </b-button>
              </span>
              <span v-else style="text-overflow: ellipsis">
                <b-button
                  size="sm"
                  variant="primary"
                  @click="
                    openNormalScanModal(
                      data.item.agent_type_id,
                      data.item.agent_type_code
                    )
                  "
                  type="button"
                  class="btn btn-primary mr-1"
                  style="position: relative"
                >
                  <span
                    v-if="isloading && data.item.agent_type_id == agent_type_id"
                    ><b-spinner type="border" small></b-spinner> Please
                    wait</span
                  >
                  <span v-else>Scan Now</span>
                </b-button>
              </span>
              <!-- Schedule button -->
              <div v-if="data.item.agent_type_code != 'attack_surface'">
                <span
                  style="text-overflow: ellipsis"
                  v-if="data.item.is_active == false"
                  v-b-tooltip.hover.v-warning
                  title="Upgrade your Plan"
                >
                  <b-button
                    :disabled="data.item.is_active == false"
                    size="sm"
                    variant="info"
                    @click="
                      openScheduleModal(
                        data.item.agent_type_id,
                        data.item.agent_type_code
                      )
                    "
                    type="button"
                    class="btn btn-primary mr-1"
                    style="position: relative"
                  >
                    Schedule Scan
                  </b-button>
                </span>
                <span v-else style="text-overflow: ellipsis">
                  <b-button
                    size="sm"
                    variant="info"
                    @click="
                      openScheduleModal(
                        data.item.agent_type_id,
                        data.item.agent_type_code
                      )
                    "
                    type="button"
                    class="btn btn-primary mr-1"
                    style="position: relative"
                  >
                    Schedule Scan
                  </b-button>
                </span>
              </div>
            </div>
          </template>
        </b-table>
        <!-- <b-pagination
          v-model="conf_currentPage"
          :total-rows="conf_rows"
          :per-page="conf_perPage"
          :align="pagination_pos"
          aria-controls="confTable"
        /> -->
      </b-card-code>

      <b-modal
        ref="modal-customize-meta-burp"
        title="Customize Scan"
        hide-footer
        no-close-on-backdrop
        size="lg"
      >
        <p class="my-1">Customize your scan here.</p>
        <AddBurpScan
          :agent_type_id="agent_type_id"
          :asset_id="asset_id"
          :scan_input_meta="scan_input_meta"
          :callBack="afterScan"
        />
      </b-modal>
      <!-- <b-modal
        ref="modal-nmap-scan"
        title="Nmap Scan"
        hide-footer
        no-close-on-backdrop
      >
        <NmapScan
          :agent_type_id="agent_type_id"
          :asset_id="asset_id"
          :callBack="afterScan"
          :agent_type="agent_type"
        />
      </b-modal> -->
      <b-modal
        ref="modal-normal-scan"
        title="Scan Options"
        hide-footer
        no-close-on-backdrop
        size="lg"
      >
        <AddNormalScan
          :agent_type_id="agent_type_id"
          :asset_id="asset_id"
          :callBack="afterScan"
          :agent_type="agent_type"
          :UrlList="loadAssetUrls"
        />
      </b-modal>
      <b-modal
        ref="modal-nessus"
        title="Nessus Scan"
        hide-footer
        no-close-on-backdrop
        size="lg"
      >
        <!-- <p class="my-1">Select Nessus Policy.</p> -->
        <NessusScan
          :agent_type_id="agent_type_id"
          :asset_id="asset_id"
          :callBack="afterScan"
        />
      </b-modal>

      <b-modal
        ref="modal-schedule-scan"
        title="Schedule Scan"
        hide-footer
        no-close-on-backdrop
        size="lg"
      >
        <AttachScheduler
          :agent_type_id="agent_type_id"
          :asset_id="asset_id"
          :callBack="afterScan"
          :agent_type="agent_type"
        />
      </b-modal>
      <b-modal
        ref="modal-schedule-zap-scan"
        title="Schedule Scan"
        hide-footer
        no-close-on-backdrop
        size="lg"
      >
        <ZapScheduler
          :agent_type_id="agent_type_id"
          :asset_id="asset_id"
          :callBack="afterScan"
          :agent_type="agent_type"
          :UrlList="loadAssetUrls"
        />
      </b-modal>

      <b-modal
        ref="modal-schedule-scan-burp"
        title="Schedule Scan"
        hide-footer
        no-close-on-backdrop
        size="lg"
      >
        <BurpScheduler
          :agent_type_id="agent_type_id"
          :asset_id="asset_id"
          :callBack="afterScan"
          :agent_type="agent_type"
          :UrlList="loadAssetUrls"
        />
      </b-modal>

      <!-- Nessus shedule modal -->
      <b-modal
        ref="modal-schedule-scan-nessus"
        title="Schedule Scan"
        hide-footer
        no-close-on-backdrop
        size="lg"
      >
        <NessusScheduler
          :agent_type_id="agent_type_id"
          :asset_id="asset_id"
          :callBack="afterScan"
          :agent_type="agent_type"
        />
      </b-modal>
      <!-- Wpscan shedule modal -->
      <b-modal
        ref="modal-schedule-wpscan"
        title="Schedule Scan"
        hide-footer
        no-close-on-backdrop
        size="lg"
      >
        <WpscanScheduler
          :agent_type_id="agent_type_id"
          :asset_id="asset_id"
          :callBack="afterScan"
          :agent_type="agent_type"
          :UrlList="loadAssetUrls"
        />
      </b-modal>
      <!-- Email Security modal -->
      <b-modal
        ref="modal-schedule-scan-dsscan"
        title="Schedule Scan"
        hide-footer
        no-close-on-backdrop
        size="lg"
      >
        <!-- <Scheduler 
            :agent_type_id="agent_type_id"
            :asset_id="asset_id"
            :callBack="afterScan"
            :agent_type="agent_type"
          /> -->
        <EmailSecurity
          :agent_type_id="agent_type_id"
          :asset_id="asset_id"
          :callBack="afterScan"
          :agent_type="agent_type"
        />
      </b-modal>
      <!-- Email IPReputation modal -->
      <b-modal
        ref="modal-schedule-scan-IPReputation"
        title="Schedule Scan"
        hide-footer
        no-close-on-backdrop
        size="lg"
      >
        <!-- <Scheduler
          :agent_type_id="agent_type_id"
          :asset_id="asset_id"
          :callBack="afterScan"
          :agent_type="agent_type"
        /> -->
        <IPReputation
          :agent_type_id="agent_type_id"
          :asset_id="asset_id"
          :callBack="afterScan"
          :agent_type="agent_type"
        />
      </b-modal>

      <!-- SSL Labs Modal -->
      <b-modal
        ref="modal-schedule-scan-SslLabs"
        title="Schedule Scan"
        hide-footer
        no-close-on-backdrop
        size="lg"
      >
        <SslLabsScan
          :agent_type_id="agent_type_id"
          :asset_id="asset_id"
          :callBack="afterScan"
          :agent_type="agent_type"
        />
        <!-- <Scheduler
          :agent_type_id="agent_type_id"
          :asset_id="asset_id"
          :callBack="afterScan"
          :agent_type="agent_type"
        /> -->
      </b-modal>
    </b-col>

    <!-- <b-col cols="6">
      <b-card class="border-primary px-5">
        <b-card-body>
          <router-link
            :to="'/assets/upload-acunetix-report/' + this.asset_id"
            class="btn btn-md btn-primary d-block"
            >Upload Acunetix Report
          </router-link>
        </b-card-body>
      </b-card>
    </b-col> -->

    <b-col cols="12">
      <b-card-code title="Schedules" no-body>
        <b-table
          responsive
          id="scheduleTable"
          :fields="schedule_fields"
          :items="schedule_items"
          :per-page="schedule_perPage"
          :current-page="schedule_currentPage"
          style="min-height: 200px"
        >
          <template #cell(schedule_id)="data">
            SC-{{ data.item.schedule_id }}
          </template>
          <template #cell(last_run_at)="data">
            {{ data.item.last_run_at | formatdate }}
          </template>
          <template #cell(scan_scheduled_day)="data">
            <span v-if="data.item.scan_scheduled_day">
              {{ data.item.scan_scheduled_day }}
              {{
                convertTimeToTimezone(
                  data.item.scan_schedule_time,
                  data.item.timezone
                )
              }}
            </span>
            <span v-else>
              {{ data.item.scan_schedule_date }}
              {{
                convertTimeToTimezone(
                  data.item.scan_schedule_time,
                  data.item.timezone
                )
              }}
            </span>
          </template>
          <!-- <template #cell(scan_scheduled_day)="data">
            <span v-if="data.item.scan_scheduled_day">
              {{ data.item.scan_scheduled_day }}
              {{ data.item.scan_schedule_time }}
              
            </span>
            <span v-else>
              {{ data.item.scan_schedule_date }}
              {{ data.item.scan_schedule_time }}
            </span>
          </template> -->
          <template #cell(scan_schedule_type)="data">
            <b-badge variant="primary" v-if="data.item.scan_schedule_type == 1"
              >Daily</b-badge
            >
            <b-badge
              variant="warning"
              v-else-if="data.item.scan_schedule_type == 2"
              >Weekly</b-badge
            >
            <b-badge
              variant="success"
              v-else-if="data.item.scan_schedule_type == 3"
              >Weekends</b-badge
            >
            <b-badge
              variant="success"
              v-else-if="data.item.scan_schedule_type == 4"
              >Continous</b-badge
            >
            <b-badge
              variant="warning"
              v-else-if="data.item.scan_schedule_type == 5"
              >Monthly</b-badge
            >
            <b-badge
              variant="info"
              v-else-if="data.item.scan_schedule_type == 6"
              >Quarterly</b-badge
            >
            <b-badge
              variant="success"
              v-else-if="data.item.scan_schedule_type == 7"
              >Once in six-Months</b-badge
            >
            <b-badge
              variant="success"
              v-else-if="data.item.scan_schedule_type == 8"
              >Once in a year
            </b-badge>
            <b-badge variant="danger" v-else>NA</b-badge>
          </template>
          <template #cell(scan_input_meta)="data">
            <b-badge
              variant="danger"
              v-if="data.item.agent_type.agent_type_code == 'masscan'"
              >{{ data.item.scan_input_meta.type }}</b-badge
            >
          </template>
          <template #cell(is_paused)="data">
            <div class="d-flex justify-content-start">
              <b-badge variant="light-success" v-if="data.item.is_paused == 0"
                >Active</b-badge
              >
              <b-badge variant="light-danger" v-else>Inactive</b-badge>
            </div>
            <b-badge
              variant="danger"
              v-if="data.item.is_paused == 0"
              style="cursor: pointer"
              title="Click to pause this schedule."
              @click="changeSchedule(data.item.schedule_id, true)"
              >Make Inactive</b-badge
            >
            <b-badge
              variant="success"
              v-if="data.item.is_paused == 1"
              style="cursor: pointer"
              title="Click to resume this schedule."
              @click="changeSchedule(data.item.schedule_id, false)"
              >Make Active</b-badge
            >
          </template>
          <template #cell(actions)="data">
            <button
              @click="deleteShedule(data.item.schedule_id)"
              type="button"
              class="btn btn-danger btn-sm mr-1"
              style="position: relative"
            >
              Delete
            </button>
          </template>
        </b-table>
        <b-pagination
          v-model="schedule_currentPage"
          :total-rows="schedule_rows"
          :per-page="schedule_perPage"
          :align="pagination_pos"
          aria-controls="scheduleTable"
      /></b-card-code>
      <b-card-code title="Scans" no-body>
        <b-table
          responsive
          id="scanTable"
          :fields="scan_fields"
          :items="scan_items"
          :per-page="scan_perPage"
          :current-page="scan_currentPage"
          hover
          style="min-height: 200px"
        >
          <template #cell(scan_status)="data">
            <b-badge variant="primary" v-if="data.item.scan_status == 0"
              >Scheduled</b-badge
            >
            <b-badge
              variant="warning"
              v-else-if="
                data.item.scan_status == 1 || data.item.scan_status == 6
              "
              >In Progress</b-badge
            >
            <b-badge variant="success" v-else-if="data.item.scan_status == 2"
              >Completed</b-badge
            >
            <b-badge variant="primary" v-else-if="data.item.scan_status == 5"
              >Pre-scheduled</b-badge
            >
            <b-badge variant="danger" :title="data.item.errors" v-else
              >Failed</b-badge
            >
          </template>

          <template #cell(is_scan_paused)="data">
            <div>
              <b-badge variant="success" v-if="!data.item.is_scan_paused"
                >No</b-badge
              >
              <b-badge variant="danger" v-else :title="data.item.errors"
                >Yes</b-badge
              >
            </div>
          </template>
          <template #cell(schedule)="data">
            <div
              v-if="
                data.item.schedule && data.item.schedule.scan_schedule_type != 0
              "
            >
              SC-{{ data.item.schedule.schedule_id }}
            </div>
          </template>
          <template #cell(last_run_at)="data">
            {{ data.item.last_run_at | formatdate }}
          </template>
          <template #cell(actions)="data">
            <!-- <button
              @click="refreshScan(data.item.scan_id, true)"
              type="button"
              class="btn btn-success btn-sm mr-1"
              style="position: relative"
            >
              Refresh
            </button>
            <button
              @click="deleteScan(data.item.scan_id)"
              type="button"
              class="btn btn-danger btn-sm mr-1"
              style="position: relative"
            >
              Delete Scan
            </button> -->
            <b-dropdown
              size="sm"
              class="ml-1"
              variant="outline-primary"
              text="Actions"
            >
              <b-dropdown-item
                v-if="data.item.scan_status == 0 || data.item.scan_status == 1"
                @click="refreshScan(data.item.scan_id)"
                >Refresh</b-dropdown-item
              >
              <!-- <b-dropdown-item @click="getDetails(data.item.scan_id)"
                >Scan Details</b-dropdown-item
              > -->
              <b-dropdown-item
                v-if="
                  (data.item.agent_type.agent_type_code == 'attack_surface' ||
                    data.item.agent_type.agent_type_code == 'masscan' ||
                    data.item.agent_type.agent_type_code == 'burpsuit' ||
                    data.item.agent_type.agent_type_code == 'nessus' ||
                    data.item.agent_type.agent_type_code == 'wpscan' ||
                    data.item.agent_type.agent_type_code == 'ssllabs' ||
                    data.item.agent_type.agent_type_code == 'zap' ||
                    data.item.agent_type.agent_type_code == 'dsscan') &&
                  data.item.scan_status == 2
                "
                @click="getSummary(data.item.scan_id)"
                >Scan Summary</b-dropdown-item
              >
              <!-- <b-dropdown-item
                v-if="
                  (data.item.scan_status == 2) & (data.item.agent_type != 6)
                "
                @click="
                  generateScanReport(
                    data.item.scan_id,
                    data.item.asset.assetOwner.profile.o_profile.org_id
                  )
                "
                >Generate Report</b-dropdown-item
              > -->
              <b-dropdown-divider />
              <b-dropdown-item @click="gotoDelete(data.item.scan_id)"
                >Delete Scan</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>
        <b-pagination
          v-model="scan_currentPage"
          :total-rows="scan_rows"
          :per-page="scan_perPage"
          :align="pagination_pos"
          aria-controls="scanTable"
        />
      </b-card-code>
    </b-col>
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete this scan? This action is irreversible
      </b-card-text>
      <div class="d-flex justify-content-end mt-1">
        <b-button @click="deleteScan()" variant="danger" size="sm">
          <span v-if="isLoading"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else>Delete</span>
        </b-button>
      </div>
    </b-modal>
  </b-row>
</template>



<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BSpinner,
  BSidebar,
  VBToggle,
  VBTooltip,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AddScan from "../AddScan.vue";
// import NmapScan from "../NmapScanner.vue"
import AddNormalScan from "../AddNormalScan.vue";
import AddBurpScan from "../AddBurpScan.vue";
// import TaskAddForm from "../TaskAddForm.vue";
import AttachScheduler from "../attach_scheduler.vue";
import BurpScheduler from "../BurpScheduler.vue";
// import moment from "moment";
import moment from "moment-timezone";
// import AddNessusScan from "../AddNessusScan.vue";
import NessusScheduler from "../NessusScheduler.vue";
import NessusScan from "../NessusScanner.vue";
// import AddEmailSecurityScan from "../AddEmailSecurityScan.vue";
import EmailSecurity from "../EmailSecurity.vue";
// import AddIPReputationScan from "../AddIpReputationScan.vue";
import IPReputation from "../IpReputation.vue";
// import AddSslLabsScan from "../AddSslLabsScan.vue";
import SslLabsScan from "../SslLabsScan.vue";
import WpscanScheduler from "../WpscanScheduler.vue";
import ZapScheduler from "../ZapSchedular.vue";

import Scheduler from "../schedular.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    AddScan,
    AddNormalScan,
    // TaskAddForm,
    AttachScheduler,
    BSidebar,
    // VBToggle,
    AddBurpScan,
    BurpScheduler,
    // AddNessusScan,
    NessusScheduler,
    NessusScan,
    WpscanScheduler,
    ZapScheduler,
    EmailSecurity,
    // AddEmailSecurityScan,
    IPReputation,
    // AddIPReputationScan,
    // AddSslLabsScan,
    SslLabsScan,
    // NmapScan,

    Scheduler,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      userId: localStorage.getItem("userid"),
      is_staff_login: this.$store.state.app.user.is_staff,

      pagination_pos: "center",
      agent_type: "",
      // Scanner attachment modal
      agent_type_id: "",
      scan_input_meta: "",
      task_title: "",
      asset_id: null,

      // Call orgaization API and return in this format
      ConfItems: [],
      conf_items: [],
      conf_currentPage: 1,
      conf_perPage: 10,
      conf_rows: 0,
      conf_fields: [
        { key: "agent_type_nicename", label: "Name" },
        { key: "description", label: "Description" },
        { key: "actions", label: "Actions" },
      ],

      scan_items: [],
      scan_currentPage: 1,
      scan_perPage: 6,
      scan_rows: 0,
      scan_fields: [
        { key: "scan_id", label: "Scan Id" },
        { key: "agent_type.agent_type_nicename", label: "Type" },
        { key: "scan_status", label: "Status" },
        // { key: "is_scan_paused", label: "Scan Status" },
        { key: "schedule", label: "Schedule" },
        { key: "last_run_at", label: "Last Run" },
        // { key: "timezone", label: "Timezone" },
        { key: "actions", label: "Actions" },
      ],

      schedule_items: [],
      schedule_currentPage: 1,
      schedule_perPage: 6,
      schedule_rows: 0,
      schedule_fields: [
        { key: "schedule_id", label: "Schedule Id" },
        { key: "agent_type.agent_type_nicename", label: "Type" },
        // { key: "scan_input_meta", label: "Scan Info" },
        { key: "last_run_at", label: "Last Run At" },
        { key: "scan_scheduled_day", label: "Scheduled Time" },
        { key: "timezone", label: "Timezone" },
        { key: "is_paused", label: "Status" },
        { key: "scan_schedule_type", label: "Schedule" },
        { key: "actions", label: "Actions" },
      ],

      polling: null,
      isloading: "",
      openDeleteModal: false,
      scan_id: "",
      isLoading: false,
    };
  },
  props: {
    loadAssetUrls: {
      type: Function,
      required: true,
    },
    asset_name: {
      type: String,
      required: true,
    },
    assetDetails: {
      type: String,
      required: true,
    },
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  computed: {
    filteredConfItems() {
      return this.conf_items
        .map((item) => {
          if (!this.isIP(this.asset_name) && this.assetDetails === "internal") {
            // Hide specific columns based on conditions
            if (
              ["attack_surface", "dsscan", "ipreputation", "ssllabs"].includes(
                item.agent_type_code
              )
            ) {
              return { ...item, isVisible: false };
            }
          } else if (
            this.isIP(this.asset_name) &&
            this.assetDetails === "internal"
          ) {
            // Hide specific columns based on conditions
            if (
              ["attack_surface", "dsscan", "ipreputation", "ssllabs"].includes(
                item.agent_type_code
              )
            ) {
              return { ...item, isVisible: false };
            }
          } else if (
            this.isIP(this.asset_name) &&
            this.assetDetails === "external"
          ) {
            // Hide specific columns based on conditions
            if (["dsscan", "ssllabs"].includes(item.agent_type_code)) {
              return { ...item, isVisible: false };
            }
          }
          // If none of the conditions match, show the column
          return { ...item, isVisible: true };
        })
        .filter((item) => item.isVisible);
    },
  },
  created() {
    this.load();
    this.loadAgentTypes();
  },
  methods: {
    // convertTimeToTimezone(time, timezone) {
    //   return moment.utc(time).tz(timezone).format("YYYY-MM-DD HH:mm A");
    // },
    isIP(value) {
      // Regular expression to match IP address format
      const ipRegex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
      return ipRegex.test(value);
    },
    convertTimeToTimezone(time, timezone) {
      const timeFormat = "HH:mm A";
      return moment.utc(time, timeFormat).tz(timezone).format(timeFormat);
    },
    loadAgentTypes: function () {
      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "agent/type?query=true&asset_id=" +
          this.$route.params.id,
      };
      var self = this;
      this.$http(a_options).then((res) => {
        console.log(res.data, "agent type list");
        this.ConfItems = res.data;
        // this.conf_items = this.conf_items.filter(
        //   (item) =>
        //     item.agent_type_code !== "amass" &&
        //     item.agent_type_code !== "emailharvester" &&
        //     item.agent_type_code !== "hibp" &&
        //     item.agent_type_code !== "cidr"
        // );
        const filteredItems = this.ConfItems.filter(
          (item) =>
            item.agent_type_code !== "amass" &&
            item.agent_type_code !== "emailharvester" &&
            item.agent_type_code !== "hibp" &&
            item.agent_type_code !== "cidr" &&
            item.agent_type_code !== "prowler" &&
            item.agent_type_code != "cloud_discovery"
        );
        this.conf_items = filteredItems;
        this.conf_rows = res.data.length;
      });
    },
    breakTextIntoLines(description) {
      const maxCharacters = 70;
      const words = description.split(/\s+/);
      const lines = [];

      let currentLine = "";

      for (const word of words) {
        if ((currentLine + word).length <= maxCharacters) {
          currentLine += (currentLine ? " " : "") + word;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      }
      if (currentLine) {
        lines.push(currentLine);
      }
      return lines.join("<br>");
    },
    load: function () {
      this.asset_id = parseInt(this.$route.params.id);
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/org-asset/" +
          this.$route.params.id +
          "/",
      };
      var self = this;
      this.$http(options).then((res) => {
        this.scan_items = res.data.scans;
        this.scan_rows = res.data.scans.length;
        res.data.schedules.map(function (value, key) {
          res.data.schedules[key].scan_input_meta = JSON.parse(
            res.data.schedules[key].scan_input_meta
          );
        });
        this.schedule_items = res.data.schedules;
        this.schedule_rows = res.data.schedules.length;
      });
    },
    loadScans: function () {
      const s_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "scan/scan?q_asset_id=" +
          this.$route.params.id,
      };
      this.$http(s_options).then((res) => {
        console.log("scans", res.data);
        this.scan_items = res.data;
        this.scan_rows = res.data.length;
      });
    },
    loadSchedules: function () {
      const s_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "scan/schedule?q_asset_id=" +
          this.$route.params.id,
      };
      this.$http(s_options).then((res) => {
        console.log("scans", res.data);
        res.data.map(function (value, key) {
          res.data[key].scan_input_meta = JSON.parse(
            res.data[key].scan_input_meta
          );
        });
        this.schedule_items = res.data;
        this.schedule_rows = res.data.length;
      });
    },
    changeSchedule: function (id, value) {
      let data = {
        is_paused: value,
      };
      const s_options = {
        method: "PATCH",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "scan/schedule/" + id + "/",
      };
      var self = this;
      this.$http(s_options).then((res) => {
        self.load();
      });
    },
    deleteShedule: function (id) {
      if (confirm("Do you really want to delete this schedule?")) {
        const options = {
          method: "DELETE",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "scan/schedule/" + id + "/",
        };
        var self = this;
        this.$http(options).then((res) => {
          if (res.data == "") {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Successfully deleted",
                icon: "TrashIcon",
                variant: "success",
              },
            });
            // this.loadSchedules();
            this.load();
          }
        });
      }
    },
    addConf: function () {
      this.$router.push({
        name: "Attach Scanner",
        params: { id: this.$route.params.id },
      });
    },
    deleteConf: function (id) {
      if (confirm("Do you really want to delete?")) {
        const options = {
          method: "DELETE",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "agent/configuration/" + id + "/",
        };
        this.$http(options).then((res) => {
          console.log(res);
          this.load();
        });
      }
    },

    doScanNow: function () {
      this.isloading = true;
      let data = {
        agent_type: this.agent_type_id,
        asset_id: this.asset_id,
        scan_created_by: this.userId,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "scan/scan/",
      };
      var self = this;
      this.$http(options)
        .then((res) => {
          console.log(res, ">>>>>>>>>>>");
          if (res.data.errors) {
            this.load();
            self.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.errors,
                // title: "Successfully added the scan request",
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.load();
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Successfully added the scan request",
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          this.isloading = false;
        })
        .catch((err) => {
          this.isloading = false;
        });
    },

    openNormalScanModal: function (id, type) {
      this.agent_type = type;
      this.agent_type_id = id;
      if (type == "masscan") this.$refs["modal-normal-scan"].show();
      if (type == "burpsuit") this.$refs["modal-customize-meta-burp"].show();
      if (type == "nessus") this.$refs["modal-nessus"].show();
      // if (type == "wpscan") this.doScanNow();
      if (type == "wpscan") this.$refs["modal-normal-scan"].show();
      if (type == "dsscan") this.doScanNow();
      if (type == "ipreputation") this.doScanNow();
      if (type == "ssllabs") this.doScanNow();
      if (type == "zap") this.$refs["modal-normal-scan"].show();
      // if (type == "attack_surface") this.$refs["modal-normal-scan"].show();
      if (type == "attack_surface") this.performAttackSurface();
      // if (type == "nmap") this.$refs["modal-nmap-scan"].show();
    },
    openScheduleModal: function (id, type) {
      this.agent_type_id = id;
      this.agent_type = type;
      if (type == "masscan") this.$refs["modal-schedule-scan"].show();
      if (type == "burpsuit") this.$refs["modal-schedule-scan-burp"].show();
      if (type == "nessus") this.$refs["modal-schedule-scan-nessus"].show();
      if (type == "wpscan") this.$refs["modal-schedule-wpscan"].show();
      if (type == "dsscan") this.$refs["modal-schedule-scan-dsscan"].show();
      if (type == "ipreputation")
        this.$refs["modal-schedule-scan-IPReputation"].show();
      if (type == "ssllabs") this.$refs["modal-schedule-scan-SslLabs"].show();
      if (type == "zap") this.$refs["modal-schedule-zap-scan"].show();
      if (type == "attack_surface") this.$refs["modal-schedule-scan"].show();
    },
    afterScan: function (callBackParams) {
      this.$refs[callBackParams].hide();
      this.load();
    },
    refreshScan: function (id, showtoast) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "scan/scan/" + id + "/refresh",
      };
      var self = this;
      this.$http(options).then((res) => {
        // if (res.data.scan_id) {
        //   if (showtoast) {
        //     self.$toast({
        //       component: ToastificationContent,
        //       props: {
        //         title: "Successfully refreshed",
        //         icon: "EditIcon",
        //         variant: "success",
        //       },
        //     });
        //   }
        //   this.load();
        //   if (res.data.scan_status == 2) {
        //     this.load(); // This is to load baseline
        //   }
        // }

        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.load();
        }
        this.load();
      });
    },
    deleteScan: function () {
      // if (
      //   confirm(
      //     "Do you really want to delete this scan? This action is irreversible."
      //   )
      // ) {
      this.isLoading = true;
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "scan/scan/" + this.scan_id + "/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data == "") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Successfully deleted",
              icon: "TrashIcon",
              variant: "success",
            },
          });
          // this.loadScans();
          this.isLoading = false;
          this.openDeleteModal = false;
          this.load();
        }
      });
      // }
    },
    performAttackSurface: function () {
      this.isloading = true;
      this.disable = true;
      //call to axios
      var self = this;
      let data = {
        target: this.asset_name,
      };
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data: data,
        url: process.env.VUE_APP_BASEURL + "scan/asm-scan/",
      };
      this.$http(options)
        .then((res) => {
          console.log(res);
          if (res.data.status == "success") {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.isloading = false;
            this.disable = false;
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
            this.isloading = false;
            this.disable = false;
          }
        })
        .catch((err) => {
          this.isloading = false;
          this.disable = false;
        });
    },
    gotoAssests: function () {
      this.$router.push({ name: "Assets" });
    },
    gotoDelete(scan_id) {
      this.openDeleteModal = true;
      this.scan_id = scan_id;
    },
    getSummary(id) {
      this.$router.push({ name: "Scan Summary", params: { id: id } });
    },
    refreshAllScans: function () {
      var self = this;
      this.polling = setInterval(() => {
        if (self.scan_items.length > 0) {
          self.scan_items.map(function (value, key) {
            self.refreshScan(value.scan_id, false);
            console.log("refreshed", value);
          });
        }
      }, 120000);
    },
  },
  // reference: https://renatello.com/vue-js-polling-using-setinterval/

  beforeDestroy() {
    // clearInterval(this.polling);
  },
};
</script>

<style lang="scss" scoped>
[dir] .dark-layout a {
  color: #fff !important;
}
</style>

