<template>
  <b-card-code title="Scan Summary">
    <b-table
      id="summaryTable"
      responsive
      :fields="fields"
      :items="upcomingScans"
      :per-page="scan_perPage"
      :current-page="scan_currentPage"
    >
      <template #cell(1)="data">
        <b-badge variant="primary" v-if="data.item[1] == 0">Scheduled</b-badge>
        <b-badge variant="warning" v-else-if="data.item[1] == 1"
          >In Progress</b-badge
        >
        <b-badge variant="success" v-else-if="data.item[1] == 2"
          >Completed</b-badge
        >
        <b-badge variant="danger" v-else>Failed</b-badge>
      </template>
    </b-table>
    <b-pagination
      v-model="scan_currentPage"
      :total-rows="scan_rows"
      :per-page="scan_perPage"
      :align="pagination_pos"
      aria-controls="summaryTable"
      size="sm"
    />
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable, BAvatar, BBadge, BPagination } from "bootstrap-vue";
export default {
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BPagination,
  },
  props: {
    upcomingScans: {
      type: Array,
      required: true,
    },
    scan_rows:{
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      items: [],
      fields: [
        { key: "2", label: "Scanners" },
        { key: "1", label: "Status" },
      ],
      scan_currentPage: 1,
      scan_perPage: 5,
      scan_rows: 0,
      pagination_pos: "center",
    };
  },
};
</script>