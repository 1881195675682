<template>
  <b-card>
    <!-- {{ assetId }} -->
    <b-row>
      <b-col md="12">
        <b-tabs ref="verficationTabs">
          <b-tab title="File Verification" active>
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">By manual file upload</h5>
                <small class="text-muted"
                  >Verify domain ownership by manual file upload</small
                >
              </b-col>
              <b-col cols="12">
                <b-card class="border-primary rounded">
                  <p>
                    Download the file on your computer using the link provided
                    below Place the text file in the root of the website
                    directory. Need help? Check out
                    <a href="">this article</a>
                  </p>
                  <p>
                    Secusy will try to access the file at the following
                    location:
                  </p>
                  <b-form-input v-model="fileVerification" disabled />
                  <p class="text-muted">
                    Click on the Verify domain button to complete the domain
                    verification process
                  </p>
                  <div class="d-flex justify-content-center">
                    <a href="_cxmth847zhtbiov3fgas0cv1h8cpw0af.txt" download>
                      <b-button variant="primary" size="sm">
                        Download
                      </b-button>
                    </a>
                  </div>
                </b-card>
              </b-col>
              <b-col cols="12" md="12 d-flex justify-content-center">
                <b-button
                  variant="outline-secondary"
                  size="sm"
                  @click="gotoverficationTabs()"
                >
                  Skip
                </b-button>
                <b-button
                  variant="outline-primary"
                  size="sm"
                  class="ml-1"
                  @click="getfileVerification()"
                >
                  Verify
                </b-button>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="DNS Verification" lazy>
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  Add the below TXT details as your DNS entry
                </h5>
              </b-col>
              <b-col cols="12">
                <b-card class="border-primary rounded">
                  <b-row>
                    <b-col md="12">
                      <b-form-group label="Type" label-for="v-txt">
                        <b-form-input id="v-txt" placeholder="TXT" disabled />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Name" label-for="v-name">
                        <b-form-input
                          id="v-name"
                          disabled
                          v-model="recod_name"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Value" label-for="v-value">
                        <b-form-input
                          id="v-value"
                          disabled
                          v-model="expected_value"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- <b-col md="12">
                              <b-form-group
                                label="Secusy will check for the DNS TXT record at"
                                label-for="v-dns"
                              >
                                <b-form-input
                                  id="v-dns"
                                  disabled
                                  placeholder="nXzLcDSvLCn55pG1Zm0xfezQfrAUDEqa"
                                />
                              </b-form-group>
                            </b-col> -->
                  </b-row>
                </b-card>
              </b-col>
              <b-col cols="12" md="12 d-flex justify-content-center">
                <b-button
                  variant="outline-secondary"
                  size="sm"
                  @click="gotoverficationTabs()"
                >
                  Skip
                </b-button>
                <b-button
                  variant="outline-primary"
                  size="sm"
                  class="ml-1"
                  @click="getDNSverification()"
                >
                  Verify
                </b-button>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="HTML Verification" lazy>
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  Copy and paste the below snippet within the head tag in the
                  HTML of your site's homepage
                </h5>
              </b-col>
              <b-col cols="12">
                <b-card class="border-primary rounded">
                  <b-row>
                    <b-col md="12">
                      <b-form-group label-for="v-meta">
                        <b-form-input
                          id="v-meta"
                          disabled
                          v-model="HTMLVerification"
                        />
                      </b-form-group>
                      <p class="text-muted">
                        Save and confirm that tag is present in the live page
                      </p>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col cols="12" md="12 d-flex justify-content-center">
                <!-- <b-button variant="outline-secondary" size="sm" @click="gotoverficationTabs()">
                          Skip
                        </b-button> -->
                <b-button
                  variant="outline-primary"
                  size="sm"
                  class=""
                  @click="getHTMLverification()"
                >
                  Verify
                </b-button>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BTab,
  BTabs,
  BSpinner,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BTab,
    BTabs,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      activeTab: 0,
      is_staff: this.$store.state.app.user.is_staff,
      assetURL: "",
      asset_name: "",
      asset_owner_id: "",
      scanners: [],

      fileVerification: "_cxmth847zhtbiov3fgas0cv1h8cpw0af.txt",
      fVerification: "",
      DNSVerification: "",
      recod_name: "_cKg6Xp9wDq2Rv4Tc3Nj8Ym5Lh1FbGsZx",
      expected_value: "_k2Pb8cN5rDqX9kZj7Mv3Fg6TtWxL1hYsR",
      HTMLVerification:
        '<meta name="_j5aB8Rt2Yn6qXpDg9Ju4F7h3KcV2xLwNt" , signature="_c9W3cJ5mR8xQ1vF6zK2nD7pB4hT0gXyLw" />',
      org_id: this.$store.state.app.organizationId,
      //   assetId: null,
      is_verified: false,
      isloading: "",
      disableBtn: false,
    };
  },
  props: {
    assetId: {
      type: Number,
      required: true,
    },
    callBack: {
      type: Function,
      required: true,
    },
  },
  methods: {
    // formSubmitted() {
    //   this.$toast({
    //     component: ToastificationContent,
    //     props: {
    //       title: "Form Submitted",
    //       icon: "EditIcon",
    //       variant: "success",
    //     },
    //   });
    // },
    gotoverficationTabs() {
      this.$refs.verficationTabs.nextTab();
    },
    goToLastTab() {
      const totalTabs = this.$refs.wizard.tabs.length;
      for (let i = 0; i < totalTabs - 1; i++) {
        this.$refs.wizard.nextTab();
      }
    },

    // addAsset() {
    //   if (!this.is_staff) this.asset_owner_id = this.$store.state.app.user.id;
    //   let data = {
    //     asset_name: this.assetURL,
    //     asset_nickname: this.asset_name,
    //     asset_type: 2,
    //     asset_owner_id: this.asset_owner_id,
    //   };
    //   let scanners = [];
    //   data.scanners = JSON.stringify(scanners);
    //   const options = {
    //     method: "POST",
    //     headers: { "content-type": "application/json" },
    //     data: data,
    //     url: process.env.VUE_APP_BASEURL + "asset/org-asset/",
    //   };
    //   this.$http(options).then((res) => {
    //     // console.log(res);
    //     if (res.data.asset_id) {
    //       (this.assetId = res.data.asset_id),
    //         (this.is_verified = res.data.is_verified),
    //         this.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: "Asset Created Successfully",
    //             autoHideDelay: 5000,
    //             icon: "EditIcon",
    //             variant: "success",
    //           },
    //         });
    //       if (this.is_verified) {
    //         this.goToLastTab();
    //       } else {
    //         this.$refs.wizard.nextTab();
    //       }
    //     }
    //     if (res.data.non_field_errors) {
    //       this.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: res.data.non_field_errors[0],
    //           autoHideDelay: 5000,
    //           icon: "EditIcon",
    //           variant: "danger",
    //         },
    //       });
    //     }
    //   });
    //   // this.isloading = false;
    // },
    getfileVerification() {
      this.$http({
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/org-asset/" +
          this.assetId +
          "/file-verification",
      }).then((res) => {
        this.fVerification = res.data;
        if (res.data.status == "error") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "danger",
            },
          });
          // if (this.is_verified) {
          //   this.goToLastTab();
          // } else {
          //   this.$refs.wizard.nextTab();
          // }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.callBack();
        }
      });
    },
    getDNSverification() {
      this.$http({
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/org-asset/" +
          this.assetId +
          "/verify-dns",
      }).then((res) => {
        this.DNSVerification = res.data;
        if (res.data.status == "error") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.callBack();
        }
      });
    },
    getHTMLverification() {
      this.$http({
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/org-asset/" +
          this.assetId +
          "/html-verification",
      }).then((res) => {
        // this.HTMLVerification = res.data;
        if (res.data.status == "error") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.callBack();
        }
      });
    },
  },
};
</script>