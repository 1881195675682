<template>
  <div>
    <b-col cols="12" v-if="baseline_items.length > 0">
      <b-card-code title="Baseline" no-body>
        <b-table
          responsive
          id="blTable"
          :fields="baseline_fields"
          :items="baseline_items"
          :per-page="baseline_perPage"
          :current-page="baseline_currentPage"
          hover
        >
          <template #cell(index)="data">
            {{ data.index + 1 + baseline_perPage * (baseline_currentPage - 1) }}
          </template>
          <template #cell(ipkey)="data">
            <span variant="primary">
              {{ data.item.ip | replace("-", ".") }}
            </span>
          </template>
          <template #cell(status)="data">
            <b-badge variant="primary" v-if="data.item.is_approved"
              >Approved</b-badge
            >
            <b-badge variant="warning" v-if="data.item.is_false_positive"
              >False Positive</b-badge
            >
          </template>

          <template #cell(actions)="data">
            <!-- <button v-if="!data.item.is_approved" @click="approveBaseline(data.item.bl_entry_id)" type="button" class="btn btn-success btn-sm mr-1" style="position: relative;"> Approve</button>

                <button v-if="!data.item.is_approved" @click="OpenMitigateModal(data.item.bl_entry_id, data.item.port, data.item.ip)" type="button" class="btn btn-warning btn-sm mr-1" style="position: relative;"> Mitigate </button> -->
            <button
              @click="deleteBaseline(data.item.bl_entry_id)"
              type="button"
              class="btn btn-danger btn-sm mr-1"
              style="position: relative"
            >
              Delete
            </button>

            <b-dropdown size="sm" variant="outline-primary" text="Actions">
              <b-dropdown-item
                v-if="!data.item.is_approved"
                @click="approveBaseline(data.item.bl_entry_id)"
                >Approve</b-dropdown-item
              >
              <b-dropdown-item
                v-if="!data.item.is_false_positive"
                @click="markAsFalsePositive(data.item.bl_entry_id)"
                >Mark as False Positive</b-dropdown-item
              >
              <b-dropdown-item
                v-if="!data.item.is_approved"
                @click="
                  OpenMitigateModal(
                    data.item.bl_entry_id,
                    data.item.port,
                    data.item.ip
                  )
                "
              >
                Mitigate</b-dropdown-item
              >
              <b-dropdown-divider />
              <b-dropdown-item @click="deleteBaseline(data.item.bl_entry_id)"
                >Delete</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>
        <b-pagination
          v-model="baseline_currentPage"
          :total-rows="baseline_rows"
          :per-page="baseline_perPage"
          :align="pagination_pos"
          aria-controls="blTable"
        />
      </b-card-code>
      <b-modal ref="modal-mitigate" title="Add Task" hide-footer size="lg">
        <p class="my-1">Add a task to mitigate this issue!</p>
        <TaskAddForm
          :asset_id="asset_id"
          :organization="organization_id"
          :callBack="afterTaskAdd"
          :task="task_title"
          task_type="BASELINE"
        />
      </b-modal>
    </b-col>
    <div v-else>
      <div
        class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
      >
        <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
        <h3 class="font-weight-bolder">No Data Found</h3>
      </div>
    </div>
  </div>
</template>


<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BSidebar,
  VBToggle,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import moment from "moment";
import TaskAddForm from "../TaskAddForm.vue";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    TaskAddForm,
    // VBToggle,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      asset_id: "",
      organization_id: this.$store.state.app.org_id,
      pagination_pos: "center",
      task_title: "",

      baseline_items: [],
      baseline_currentPage: 1,
      baseline_perPage: 10,
      baseline_rows: 0,
      baseline_fields: [
        { key: "index", label: "#" },
        // { key: "bl_entry_id", label: "id" },
        { key: "ipkey", label: "Ip" },
        { key: "port", label: "Port" },
        { key: "port_type", label: "Type" },
        { key: "port_state", label: "State" },
        { key: "status", label: "Status" },
        { key: "actions", label: "Actions" },
      ],
    };
  },
  filters: {
    replace: function (ip, rep, repWith) {
      const result = ip.split(rep).join(repWith);
      return result;
    },
  },
  created() {
    this.load();
  },
  methods: {
    load: function () {
      this.open_ports = [];
      this.asset_id = parseInt(this.$route.params.id);
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/org-asset/" +
          this.$route.params.id +
          "/",
      };
      var self = this;
      this.$http(options).then((res) => {
        // this.organization_id = res.data.assetOwner.profile.organization;
        // this.organization_id = this.$store.state.app.org_id;

        console.log("basline", res.data.baseline);
        if (res.data.baseline.length) {
          this.baseline_items = res.data.baseline[0].base_line_entries;
          this.baseline_rows = res.data.baseline[0].base_line_entries.length;
        }
      });
    },
    approveBaseline: function (id) {
      if (confirm("Do you really want to approve this port?")) {
        let data = {
          bl_entry_id: id,
          is_approved: true,
          is_false_positive: false,
        };
        const options = {
          method: "PATCH",
          headers: { "content-type": "application/json" },
          data: data,
          url:
            process.env.VUE_APP_BASEURL +
            "asset/baseline/" +
            id +
            "/approve-baseline/", //+'scan/scan/'+id+'/refresh',
        };
        this.$http(options).then((res) => {
          console.log(res);
          this.load();
        });
      }
    },
    markAsFalsePositive: function (id) {
      if (confirm("Do you really want to mark this as false positive?")) {
        let data = {
          bl_entry_id: id,
          is_false_positive: true,
          is_approved: false,
        };
        const options = {
          method: "PATCH",
          headers: { "content-type": "application/json" },
          data: data,
          url:
            process.env.VUE_APP_BASEURL +
            "asset/baseline/" +
            id +
            "/approve-baseline/",
        };
        this.$http(options).then((res) => {
          console.log(res);
          this.load();
        });
      }
    },
    deleteBaseline: function (id) {
      if (
        confirm(
          "Do you really want to delete this entry? We highly recommend to `mitigate` the issue before deleting the entry from baseline"
        )
      ) {
        let data = {
          bl_entry_id: id,
        };
        const options = {
          method: "DELETE",
          headers: { "content-type": "application/json" },
          data: data,
          url: process.env.VUE_APP_BASEURL + "asset/baseline/" + id + "/",
        };
        this.$http(options).then((res) => {
          console.log(res);
          this.load();
        });
      }
    },
    OpenMitigateModal: function (id, port, ip) {
      this.task_title = "Please close port " + port + " of asset " + ip;
      this.$refs["modal-mitigate"].show();
    },
    afterTaskAdd: function () {
      this.$refs["modal-mitigate"].hide();
    },
  },
  // reference: https://renatello.com/vue-js-polling-using-setinterval/

  beforeDestroy() {
    // clearInterval(this.polling);
  },
};
</script>

<style scoped>
::v-deep .dropdown-menu {
  max-height: 100px;
  overflow-y: auto;
}
</style>
