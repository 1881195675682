var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card-code',{attrs:{"no-header":""}},[_c('validation-observer',{ref:"AssetUrlAddForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form",on:{"submit":function($event){$event.preventDefault();return _vm.EditAssetUrl()}}},[_c('b-form-group',{attrs:{"label":"Url Name","label-for":"Name"}},[_c('validation-provider',{attrs:{"name":"AssetUrl Name","rules":{
              required: true,
              regex: /^[-_a-zA-Z0-9\s\(\)\ [\]\ {\} \|\/ \:\.]+$/,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"AssetUrl_name","state":errors.length > 0 ? false : null,"name":"AssetUrl_name"},model:{value:(_vm.AssetUrlName),callback:function ($$v) {_vm.AssetUrlName=$$v},expression:"AssetUrlName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{staticClass:"my-1",attrs:{"type":"submit","variant":"primary","disabled":invalid,"size":"sm"}},[(_vm.isloading)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v(" Please wait")],1):_c('span',[_vm._v("Update")])])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }