<template>
  <validation-observer ref="AssetEditForm" #default="{ invalid }">
    <form ref="form" @submit.prevent="scanNow">
      <b-form-input
        id="agent_conf_id"
        v-model="agent_conf_id"
        name="agent_conf_id"
        hidden
      />
      <b-form-group label="Meta" label-for="Meta Input">
        <validation-provider #default="{ errors }" name="Meta" rules="required">
          <b-form-textarea
            id="customScanInputMeta"
            :state="errors.length > 0 ? false : null"
            v-model="customScanInputMeta"
          ></b-form-textarea>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-button type="submit" variant="primary" :disabled="invalid">
        Scan
      </b-button>
    </form>
  </validation-observer>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { integer } from "vee-validate/dist/rules";
export default {
  data() {
    return {
      userId: localStorage.getItem("userid"),
      customScanInputMeta: "",
    };
  },
  props: {
    agent_conf_id: {
      type: Number,
      required: true,
    },
    scan_input_meta: {
      type: String,
      required: true,
    },
    callBack: {
      type: Function,
      required: true,
    },
  },
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },
  created: function () {
    this.load();
  },
  methods: {
    load: function () {
      this.customScanInputMeta = this.scan_input_meta;
    },
    scanNow: function () {
      // Open pop up
      //this.$parent.$refs['modal-customize-meta'].hide()
      let data = {
        agent_configuration_id: this.agent_conf_id,
        scan_created_by: this.userId,
        scan_input_meta: this.customScanInputMeta,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "scan/scan/",
      };
      var self = this;
      this.$http(options).then((res) => {
        const callBackParams = "modal-customize-meta";
        if (res.data.scan_id) {
          this.callBack(callBackParams);
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Successfully added the scan request",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.callBack(callBackParams);
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Trouble while processing the scan request",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
  },
};
</script>