<template>
  <!-- form -->
  <validation-observer ref="ScanScheduleForm">
    <b-form class="auth-login-form mt-2" @submit.prevent="scheduleScan">
      <!-- Scanner-->
      <!-- <b-form-group label-for="urls" v-if="!this.is_group">
        <template #label>
          <span>URLs</span>
          <b-button
            class="ml-1"
            style="padding: 0.186rem 1rem !important"
            @click="gotoUrl()"
            variant="outline-success"
            size="sm"
            >+
          </b-button>
        </template>
        <validation-provider #default="{ errors }" name="url" rules="required">
          <v-select
            v-model="urlName"
            label="text"
            placeholder="--Select URL--"
            :options="url_options"
            autocomplete
            style="width: 100%"
            :reduce="(urlName) => urlName.value"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group> -->
      <b-form-group label="Repeat By" label-for="Schedule">
        <template #label>
          <span>Repeat By</span>
          <span class="text-danger font-weight-bold h5" style="margin-left: 3px"
            >*</span
          >
        </template>
        <validation-provider
          #default="{ errors }"
          name="schedule_selected"
          rules="required"
        >
          <b-form-select
            v-model="schedule_selected"
            :options="schedule"
            :state="errors.length > 0 ? false : null"
          ></b-form-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <div v-if="schedule_selected === 1">
        <div class="row">
          <div class="col-4">
            <b-form-group label="Time" label-for="Time">
              <v-select
                v-model="hours"
                label="text"
                placeholder="--Time --"
                :options="hours_options"
                autocomplete
                style="width: 100%"
                :reduce="(hours) => hours.value"
              >
              </v-select>
            </b-form-group>
          </div>
          <div class="col-8">
            <b-form-group label="Timezone" label-for="Timezone">
              <v-select
                v-model="selectedTimeZone"
                label="text"
                placeholder="--Select Time Zone--"
                :options="timeZones"
                autocomplete
                style="width: 100%"
                :reduce="(selectedTimeZone) => selectedTimeZone.value"
              >
              </v-select>
            </b-form-group>
          </div>
        </div>
      </div>
      <div v-if="schedule_selected === 2">
        <b-form-group label="Select Days" label-for="Days">
          <template #label>
            <span>Select Days</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="Day"
            rules="required"
          >
            <b-form-select
              v-model="day_selected"
              :options="days_options"
              :state="errors.length > 0 ? false : null"
            ></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <div class="row">
          <div class="col-4">
            <b-form-group label="Time" label-for="Time">
              <v-select
                v-model="hours"
                label="text"
                placeholder="--Time --"
                :options="hours_options"
                autocomplete
                style="width: 100%"
                :reduce="(hours) => hours.value"
              >
              </v-select>
            </b-form-group>
          </div>
          <div class="col-8">
            <b-form-group label="Timezone" label-for="Timezone">
              <v-select
                v-model="selectedTimeZone"
                label="text"
                placeholder="--Select Time Zone--"
                :options="timeZones"
                autocomplete
                style="width: 100%"
                :reduce="(selectedTimeZone) => selectedTimeZone.value"
              >
              </v-select>
            </b-form-group>
          </div>
        </div>
      </div>
      <div v-if="schedule_selected === 5">
        <div class="row">
          <div class="col-12">
            <b-form-group label="Date" label-for="Date">
              <flat-pickr
                placeholder="--Select Date--"
                style="width: 100%"
                v-model="scanDate"
                class="form-control"
              />
            </b-form-group>
          </div>

          <div class="col-4">
            <b-form-group label="Time" label-for="Time">
              <v-select
                v-model="hours"
                label="text"
                placeholder="--Time --"
                :options="hours_options"
                autocomplete
                style="width: 100%"
                :reduce="(hours) => hours.value"
              >
              </v-select>
            </b-form-group>
          </div>
          <div class="col-8">
            <b-form-group label="Timezone" label-for="Timezone">
              <v-select
                v-model="selectedTimeZone"
                label="text"
                placeholder="-- Select Time Zone --"
                :options="timeZones"
                autocomplete
                style="width: 100%"
                :reduce="(selectedTimeZone) => selectedTimeZone.value"
              >
              </v-select>
            </b-form-group>
          </div>
        </div>
      </div>

      <b-button
        type="submit"
        variant="primary"
        :disabled="isBtnDisable || schedule_selected === ''"
        size="sm"
      >
        <span v-if="isLoading"
          ><b-spinner type="border" small></b-spinner> Please wait</span
        >
        <span v-else>Schedule</span>
      </b-button>
    </b-form>
    <b-modal
      title="Add New URL"
      hide-footer
      no-close-on-backdrop
      size="lg"
      v-model="urlModal"
    >
      <CreateAssetUrl :assetId="asset_id" :closeModal="closeModal" />
    </b-modal>
  </validation-observer>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BFormTextarea,
  BSpinner,
  BFormSelectOption,
  BTime,
  BFormTimepicker,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { integer } from "vee-validate/dist/rules";
import flatPickr from "vue-flatpickr-component";
import moment from "moment-timezone";
import vSelect from "vue-select";
import CreateAssetUrl from "../components/asset_url/add.vue";
export default {
  data() {
    return {
      userId: localStorage.getItem("userid"),
      schedule_selected: "",
      schedule: [
        { value: null, text: "--Select Schedule--" },
        { value: 1, text: "Daily" },
        { value: 2, text: "Weekly [Mon- Sun]" },
        // { value: 3, text: "Weekends [Sat or Sun]" },
        { value: 5, text: "Monthly " },
      ],
      day_selected: "",
      days_options: [
        { value: "Monday", text: "Monday" },
        { value: "Tuesday", text: "Tuesday" },
        { value: "Wednesday", text: "Wednesday" },
        { value: "Thursday", text: "Thursday" },
        { value: "Friday", text: "Friday" },
        { value: "Saturday", text: "Saturday" },
        { value: "Sunday", text: "Sunday" },
      ],
      hours: "",
      hours_options: [
        { value: "01:00:00", text: "01:00" },
        { value: "02:00:00", text: "02:00" },
        { value: "03:00:00", text: "03:00" },
        { value: "04:00:00", text: "04:00" },
        { value: "05:00:00", text: "05:00" },
        { value: "06:00:00", text: "06:00" },
        { value: "07:00:00", text: "07:00" },
        { value: "08:00:00", text: "08:00" },
        { value: "09:00:00", text: "09:00" },
        { value: "10:00:00", text: "10:00" },
        { value: "11:00:00", text: "11:00" },
        { value: "12:00:00", text: "12:00" },
        { value: "13:00:00", text: "13:00" },
        { value: "14:00:00", text: "14:00" },
        { value: "15:00:00", text: "15:00" },
        { value: "16:00:00", text: "16:00" },
        { value: "17:00:00", text: "17:00" },
        { value: "18:00:00", text: "18:00" },
        { value: "19:00:00", text: "19:00" },
        { value: "20:00:00", text: "20:00" },
        { value: "21:00:00", text: "21:00" },
        { value: "22:00:00", text: "22:00" },
        { value: "23:00:00", text: "23:00" },
        { value: "00:00:00", text: "24:00" },
      ],
      scanDate: "",
      isLoading: "",
      isBtnDisable: false,
      timeZone: "",
      timeZones: [],
      selectedTimeZone: null,
      url_options: [],
      // urlName: null,
      urlModal: false,
    };
  },
  props: {
    asset_id: {
      type: Number,
      required: true,
    },
    agent_type_id: {
      type: Number,
      required: true,
    },
    agent_type: {
      type: String,
      required: true,
    },
    callBack: {
      type: Function,
      required: true,
    },
    is_group: {
      type: Boolean,
      required: false,
    },
    asset_group_id: {
      type: Number,
      required: true,
    },
  },
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BSpinner,
    BFormSelectOption,
    flatPickr,
    BTime,
    BFormTimepicker,
    vSelect,
    CreateAssetUrl,
  },
  created() {
    this.load();
    if (!this.is_group) {
      this.loadAssetUrls();
    }

    this.populateTimeZones();
    this.setDefaultTimeZone();
  },
  methods: {
    load: function () {},
    populateTimeZones() {
      this.timeZones = moment.tz
        .names()
        .filter((tz) => tz !== "Asia/Calcutta")
        .map((tz) => ({ text: tz, value: tz }));
      // this.timeZones = moment.tz.names();
    },
    setDefaultTimeZone() {
      const localTimeZone = moment.tz.guess();
      const updatedTimeZone =
        localTimeZone === "Asia/Calcutta" ? "Asia/Kolkata" : localTimeZone;
      this.selectedTimeZone = updatedTimeZone;
    },
    loadAssetUrls() {
      this.open_ports = [];
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/asset-url?asset_id=" +
          this.asset_id,
      };
      var self = this;
      this.$http(options).then((res) => {
        res.data.results.map(function (value, key) {
          let a = {
            value: res.data.results[key].url_id,
            text: res.data.results[key].url,
          };
          self.url_options.push(a);
        });
      });
    },
    gotoUrl() {
      this.urlModal = true;
    },
    closeModal() {
      this.urlModal = false;
      this.loadAssetUrls();
    },
    scheduleScan: function () {
      // Open pop up
      this.isBtnDisable = true;
      this.isLoading = true;
      let data = {
        agent_type: this.agent_type_id,
        //asset_id: this.asset_id,
        scan_created_by: this.userId,
        is_scan_scheduled: 1,
        scan_schedule_type: this.schedule_selected,
        scan_schedule_time: this.hours,
        timezone: this.selectedTimeZone,
      };
      if (this.day_selected != "") {
        data["scan_scheduled_day"] = this.day_selected;
      }
      if (this.scanDate) {
        data["scan_schedule_date"] = this.scanDate;
      }
      if (this.is_group) {
        data["asset_group"] = this.asset_group_id;
        // data["url_id"] = null;
      } else {
        data["asset_id"] = this.asset_id;
        // data["url_id"] = this.urlName;
      }
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "scan/schedule/",
      };
      var self = this;
      this.$http(options)
        .then((res) => {
          console.log(res, ">>>>>>>>>>");
          const callBackParams = "modal-schedule-scan-IPReputation";
          if (res.data.schedule_id) {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Successfully added the scan request",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.isLoading = false;
            this.isBtnDisable = false;
            this.callBack(callBackParams);
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.isBtnDisable = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>