var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{attrs:{"cols":"12"}},[_c('b-card-code',[_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('div',[_c('h4',{staticClass:"text-left ml-2 mb-1"},[_vm._v("Vulnerabilities")])]),_c('b-row',{staticClass:"ml-1 mr-1"},[_c('b-col',[_c('div',{staticClass:"border-purple rounded text-center bg-blue-grad bg-purple cursor-pointer custom-card",on:{"click":function($event){return _vm.$router.push({
                  path: '/assets/vulnerabilities',
                  query: {
                    severity: 'critical',
                    status: [0, 3, 5],
                    asset_id: _vm.asset_id,
                    tabIndex: 1,
                  },
                })}}},[_c('span',{staticClass:"h5 text-purple"},[_vm._v(" "+_vm._s(_vm.items.vuln_data.critical_vulns))]),_c('br'),_c('span',{staticClass:"text-purple h6"},[_vm._v("Critical")])])]),_c('b-col',[_c('div',{staticClass:"border-danger rounded text-center bg-light-danger bg-blue-grad cursor-pointer custom-card",on:{"click":function($event){return _vm.$router.push({
                  path: '/assets/vulnerabilities',
                  query: {
                    severity: 'high',
                    status: [0, 3, 5],
                    asset_id: _vm.asset_id,
                    tabIndex: 1,
                  },
                })}}},[_c('span',{staticClass:"h5 text-danger"},[_vm._v(" "+_vm._s(_vm.items.vuln_data.high_vulns))]),_c('br'),_c('span',{staticClass:"text-danger h6"},[_vm._v("High")])])]),_c('b-col',[_c('div',{staticClass:"border-warning rounded text-center bg-blue-grad bg-light-warning cursor-pointer custom-card",on:{"click":function($event){return _vm.$router.push({
                  path: '/assets/vulnerabilities',
                  query: {
                    severity: 'medium',
                    status: [0, 3, 5],
                    asset_id: _vm.asset_id,
                    tabIndex: 1,
                  },
                })}}},[_c('span',{staticClass:"h5 text-warning"},[_vm._v(" "+_vm._s(_vm.items.vuln_data.medium_vulns))]),_c('br'),_c('span',{staticClass:"text-warning h6"},[_vm._v("Medium")])])]),_c('b-col',[_c('div',{staticClass:"border-success rounded text-center bg-blue-grad bg-light-success cursor-pointer custom-card",on:{"click":function($event){return _vm.$router.push({
                  path: '/assets/vulnerabilities',
                  query: {
                    severity: 'low',
                    status: [0, 3, 5],
                    asset_id: _vm.asset_id,
                    tabIndex: 1,
                  },
                })}}},[_c('span',{staticClass:"h5 text-success"},[_vm._v(" "+_vm._s(_vm.items.vuln_data.low_vulns))]),_c('br'),_c('span',{staticClass:"text-success h6"},[_vm._v("Low")])])]),_c('b-col',[_c('div',{staticClass:"border-info rounded text-center bg-blue-grad bg-light-info cursor-pointer custom-card",on:{"click":function($event){return _vm.$router.push({
                  path: '/assets/vulnerabilities',
                  query: {
                    severity: 'info',
                    status: [0, 3, 5],
                    asset_id: _vm.asset_id,
                    tabIndex: 1,
                  },
                })}}},[_c('span',{staticClass:"h5 text-info"},[_vm._v(" "+_vm._s(_vm.items.vuln_data.info_vulns))]),_c('br'),_c('span',{staticClass:"text-info h6"},[_vm._v("Info")])])])],1)],1),_c('b-col',{staticClass:"border-right my-1",attrs:{"lg":"4"}},[_c('VulnOverview',{attrs:{"values":_vm.vuln_values}})],1),_c('b-col',{staticClass:"border-right my-1",attrs:{"lg":"4"}},[_c('TaskData',{attrs:{"values":_vm.task_values}})],1),_c('b-col',{staticClass:"my-1 border-right",attrs:{"lg":"4"}},[_c('UpcomingScans',{attrs:{"upcomingScans":_vm.upcomingScans,"scan_rows":_vm.scan_rows}})],1),_c('b-col',{staticClass:"my-1 d-flex align-items-center",attrs:{"lg":"4"}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"border-primary rounded text-center bg-blue-grad mb-1 mt-1 cursor-pointer custom-card",on:{"click":function($event){return _vm.$router.push({
                      path: '/scans',
                      query: { status: 2 },
                    })}}},[_c('span',{staticClass:"h5 text-primary"},[_vm._v(" "+_vm._s(_vm.items.scan_data.completed_scans))]),_c('br'),_c('span',{staticClass:"text-primary h6"},[_vm._v("Completed Scans")])])]),_c('b-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"border-primary rounded text-center bg-blue-grad mb-1 cursor-pointer custom-card",on:{"click":function($event){return _vm.$router.push({
                      path: '/scans',
                      query: { status: 1 },
                    })}}},[_c('span',{staticClass:"h5 text-primary"},[_vm._v(" "+_vm._s(_vm.items.scan_data.active_scan))]),_c('br'),_c('span',{staticClass:"text-primary h6"},[_vm._v("Active Scans")])])]),_c('b-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"border-primary rounded text-center bg-blue-grad mb-1 cursor-pointer custom-card",on:{"click":function($event){return _vm.$router.push({
                      path: '/schedules',
                    })}}},[_c('span',{staticClass:"h5 text-primary"},[_vm._v(" "+_vm._s(_vm.items.scan_data.active_schedules))]),_c('br'),_c('span',{staticClass:"text-primary h6"},[_vm._v("Active Schedules")])])]),_c('b-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"border-primary rounded text-center bg-blue-grad mb-1"},[_c('span',{staticClass:"h5 text-primary"},[_vm._v(" "+_vm._s(_vm.items.insecure_port_data.insecure_port_count))]),_c('br'),_c('span',{staticClass:"text-primary h6"},[_vm._v("Insecure Port Count")])])])],1)],1)],1)],1),_c('b-col',{staticClass:"my-1 border-left",attrs:{"lg":"8"}},[_c('InsecurePort',{staticClass:"my-1",attrs:{"insecureportList":_vm.insecureportList}})],1)],1),_c('b-row')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }