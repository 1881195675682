<template>
  <div>
    <validation-observer ref="AssignAssetsAddForm">
      <b-form class="auth-login-form mt-2" @submit.prevent="addAssignAssets">
        <div class="col-12">
          <div class="w-100" v-if="!isCheckAllFilterResults">
            <span style="color: #5e5873; font-size: 0.857rem"
              >Asset Name :</span
            >
            <div class="w-100">
              <b-badge
                v-for="asset in asset_id"
                :key="asset"
                variant="light-primary"
                class="mb-1 mr-1"
              >
                {{ asset.asset_name }}
              </b-badge>
            </div>
          </div>
          <div v-else class="w-100">
            <div v-for="f in filter" :key="f" class="d-flex">
              <b-badge variant="light-primary" v-if="f.group" class="mb-1">
                {{ f.group.text }}
              </b-badge>
              <span v-if="f.risk_level" class="mb-1">
                <!-- {{ f.risk_level.text }} -->
                <span v-for="r in f.risk_level" :key="r">
                  <b-badge variant="light-primary" class="mr-1" v-if="r == 0"
                    >Unknown</b-badge
                  >
                  <b-badge variant="light-primary" class="mr-1" v-if="r == 1"
                    >Safe</b-badge
                  >
                  <b-badge variant="light-primary" class="mr-1" v-if="r == 2"
                    >Low</b-badge
                  >
                  <b-badge variant="light-primary" class="mr-1" v-if="r == 3"
                    >Medium</b-badge
                  >
                  <b-badge variant="light-primary" class="mr-1" v-if="r == 4"
                    >High</b-badge
                  >
                  <b-badge variant="light-primary" class="mr-1" v-if="r == 5"
                    >Critical</b-badge
                  >
                </span>
              </span>
              <b-badge
                variant="light-primary"
                v-if="f.asset_grouptype"
                class="mb-1"
              >
                {{ f.asset_grouptype }}
              </b-badge>
            </div>
          </div>

          <b-form-group label="Select User" label-for="user">
            <template #label>
              <span>Select User</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <v-select
              v-model="user"
              label="text"
              placeholder="--Search User--"
              :options="users"
              @search="fetchUsers"
              autocomplete
              :reduce="(user) => user.value"
            />
          </b-form-group>
        </div>
        <div class="row">
          <div class="col-12">
            <b-button
              type="submit"
              variant="primary"
              size="sm"
              class="ml-1"
              :disabled="this.user === null || disabled"
            >
              <span v-if="isLoading">
                <b-spinner type="border" small></b-spinner>
                Please wait
              </span>
              <span v-else>Add</span>
            </b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormSelect,
  BBadge,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormSelect,
    BBadge,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      is_staff: this.$store.state.app.user.is_staff,
      //   organization: this.$store.state.app.org_id,
      asset_id: [],
      assets: [],
      asset_options: [],
      user: null,
      users: [],
      //   organization: "",
      organizations: [],
      isLoading: false,
      disabled:false,
    };
  },
  props: {
    asset_id: {
      type: Array,
      required: true,
    },
    organization: {
      type: Number,
      required: true,
    },
    isCheckAllFilterResults: {
      type: Boolean,
      required: true,
    },
    filter: {
      type: Object,
      required: true,
    },
    parentCallBack: Function,
  },

  methods: {
    load: function () {},
    fetchUsers: function (search) {
      if (search.length > 1) {
        // this.users = [{ value: null, text: "--Select User--" }];
        // Load User drop down
        let url =
          process.env.VUE_APP_BASEURL +
          "user/users/get-all-users/?query=true" +
          "&name=" +
          search;

        if (this.is_staff) {
          url = url + "&q_org_id=" + this.organization;
        }
        const o_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url: url,
        };
        var self = this;

        this.$http(o_options).then((res) => {
          this.users = [{ value: null, text: "--Search User--" }];
          // this.users =res.data
          res.data.map(function (value, key) {
            let user = {
              value: res.data[key].id,
              text: res.data[key].first_name + " " + res.data[key].last_name,
            };
            self.users.push(user);
          });
        });
      }
    },
    addAssignAssets: function () {
      this.$refs.AssignAssetsAddForm.validate().then((success) => {
        this.disabled = true;
        this.isLoading = true;
        if (success) {
          let data = {
            user_id: this.user,
            assets: this.asset_id.map((asset) => asset.asset_id),
            // assets: this.asset_id,
          };
          if (this.isCheckAllFilterResults) {
            data.filters = this.filter.map((item) => {
              if (item.group && typeof item.group === "object") {
                return { group: item.group.value };
              }
              if (item.risk_level && typeof item.risk_level === "object") {
                return { risk_level: item.risk_level.value };
              }

              return item;
            });

            // data.filters = this.filter;
          }
          const options = {
            method: "POST",
            data: data,
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_BASEURL + "asset/assign-asset-to-user/",
          };
          var self = this;
          this.$http(options).then((res) => {
            if (res.data.status == "success") {
              //   this.$router.push({
              //     name: "Assets",
              //   });
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.isLoading = false;
            } else {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
              this.isLoading = false;
            }
            this.isLoading = false;
            this.disabled = false;
            this.parentCallBack();
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
