

<template>
  <div>
    <b-card-code no-header>
      <validation-observer ref="AssetUrlAddForm" #default="{ invalid }">
        <b-form class="auth-login-form" @submit.prevent="EditAssetUrl()">
          <!-- AssetUrl_name Name -->
          <b-form-group label="Url Name" label-for="Name">
            <validation-provider
              #default="{ errors }"
              name="AssetUrl Name"
              :rules="{
                required: true,
                regex: /^[-_a-zA-Z0-9\s\(\)\ [\]\ {\} \|\/ \:\.]+$/,
              }"
            >
              <b-form-input
                id="AssetUrl_name"
                v-model="AssetUrlName"
                :state="errors.length > 0 ? false : null"
                name="AssetUrl_name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            type="submit"
            variant="primary"
            :disabled="invalid"
            class="my-1"
            size="sm"
          >
            <span v-if="isloading"
              ><b-spinner type="border" small></b-spinner> Please wait</span
            >
            <span v-else>Update</span>
          </b-button>
        </b-form>
      </validation-observer>
    </b-card-code>
  </div>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    BSpinner,
    vSelect,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    assetId: {
      type: Number,
      required: true,
    },
    urlId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      AssetUrlName: null,
      isloading: false,
    };
  },
  mounted() {
    this.loadAssetUrls();
  },
  methods: {
    loadAssetUrls() {
      this.open_ports = [];
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "asset/asset-url?" + this.urlId,
      };
      var self = this;
      this.$http(options).then((res) => {
        this.AssetUrlName = res.data.results[0].url;
      });
    },
    EditAssetUrl: function () {
      this.$refs.AssetUrlAddForm.validate().then((success) => {
        this.isloading = true;
        if (success) {
          var self = this;
          let data = {
            url: this.AssetUrlName,
            asset_id: this.assetId,
          };
          const options = {
            method: "PUT",
            headers: { "content-type": "application/json" },
            data: data,
            url:
              process.env.VUE_APP_BASEURL +
              "asset/asset-url/" +
              this.urlId +
              "/",
          };
          this.$http(options)
            .then((res) => {
              if (res.data.url_id) {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Successfully Updated",
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
              } else {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res,
                    icon: "EditIcon",
                    variant: "danger",
                  },
                });
                this.closeModal();
              }
              this.closeModal();
            })
            .catch((err) => {
              this.closeModal();
              console.log(err);
            });
          this.isloading = false;
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>