<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Task Summary</b-card-title>
    </b-card-header>

    <b-card-body>
      <!-- apex chart -->
      <vue-apex-charts
        type="donut"
        height="325"
        class="mt-2 mb-1"
        :options="customersPie.chartOptions"
        :series="values"
      />

      <!-- list group -->
      <div class="pt-25">
        <div
          v-for="(data, index) in chartData.listData"
          :key="index"
          class="d-flex justify-content-between"
          :class="
            index === Object.keys(chartData.listData).length - 1 ? '' : 'mb-1'
          "
        >
          <div class="series-info">
            <feather-icon
              :icon="data.icon"
              size="16"
              class="mr-50"
              :class="data.iconColor"
            />
            <span class="font-weight-bolder">{{ data.text }}</span>
          </div>
          <span>{{ data.result }}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    VueApexCharts,
  },
  props: {
    values: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartData: {},
      customersPie: {
        series: [690, 258, 149],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: [ "Open", "Completed", "In Progress"],
          dataLabels: {
            enabled: true,
          },
          legend: { show: true },
          stroke: {
            width: 4,
          },
          colors: [
            // $themeColors.primary,
            $themeColors.danger,
            $themeColors.success,
            $themeColors.warning,
          
          ],
        },
      },
    };
  },
};
</script>
