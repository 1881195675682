<template>
  <b-col cols="12">
    <b-card-code title="Asset Logs" no-body>
      <div v-if="log_items.length">
        <div class="row my-1">
          <div class="col-4"></div>
          <div class="col-8 d-flex">
            <b-form-input
              class="w-100 mr-1"
              id="name"
              v-model="name_filter"
              name="name_filter"
              placeholder="Search with message"
              size="sm"
            />
            <b-form-input
              class="w-100"
              id="email"
              v-model="email"
              name="email"
              placeholder="Search with user email"
              size="sm"
            />
            <b-button
              class="ml-1 mr-1"
              variant="primary"
              size="sm"
              @click="searchFn"
            >
              Search
            </b-button>
          </div>
        </div>
        <b-table
          responsive
          id="LogTable"
          :fields="log_fields"
          :items="log_items"
          :per-page="log_perPage"
          :current-page="log_currentPage"
          hover
        >
          <template #cell(index)="data">
            {{ data.index + 1 + log_perPage * (log_currentPage - 1) }}
          </template>
          <template #cell(log_message)="data">
            <p v-html="data.item.log_message"></p>
          </template>
          <template #cell(activity_on)="data">
            <b-badge variant="light-primary">{{
              data.item.activity_on
            }}</b-badge>
          </template>
          <!-- <template #cell(activity_time)="data">
          <span>{{ new Date(data.item.activity_time).toLocaleString() }}</span>
        </template> -->
          <template #cell(activity_time)="data">
            <div
              class="d-flex justify-content-between"
              style="white-space: nowrap; overflow: hidden"
            >
              <span style="text-overflow: ellipsis" class="text-capitalize">{{
                data.item.activity_time | formatdate
              }}</span>
            </div>
          </template>
        </b-table>

        <b-pagination
          v-model="log_currentPage"
          :total-rows="log_rows"
          :per-page="log_perPage"
          :align="pagination_pos"
          aria-controls="LogTable"
        />
      </div>

      <div v-else>
        <div
          class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
        >
          <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
          <h3 class="font-weight-bolder">No Data Found</h3>
        </div>
      </div>
    </b-card-code>
  </b-col>
</template>


<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BSidebar,
  VBToggle,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import moment from "moment";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    // VBToggle,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      pagination_pos: "center",

      log_items: [],
      log_currentPage: 1,
      log_perPage: 10,
      log_rows: 0,
      log_fields: [
        { key: "index", label: "#" },
        { key: "log_message", label: "Log Message" },
        { key: "activity_on", label: "Activity on" },
        // { key: "user_email", label: "User Email" },
        // { key: "ref_id", label: "Ref ID" },
        { key: "activity_time", label: "Activity Time" },
      ],
      name_filter: "",
      email: "",
      filter: [],
    };
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },

  created() {
    this.loadLogs();
  },
  methods: {
    loadLogs: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "logs/v1/logs/asset-logs?asset_id=" +
          this.$route.params.id,
      };
      this.$http(options).then((res) => {
        console.log(res.data, "log res");
        this.log_items = res.data.results;
        this.log_rows = res.data.length;
      });
    },
    searchFn: function () {
      this.filter = [];
      let url =
        process.env.VUE_APP_BASEURL +
        "logs/v1/logs/asset-logs?asset_id=" +
        this.$route.params.id;
      if (this.name_filter != null && this.name_filter != "") {
        this.filter.push({ name_filter: this.name_filter });
        url = url + "&log_msg=" + this.name_filter;
      }
      if (this.email != null && this.email != "") {
        this.filter.push({ email: this.email });
        url = url + "&email=" + this.email;
      }
      if (
        this.name_filter == null &&
        this.name_filter == "" &&
        this.email == null &&
        this.email == ""
      ) {
        this.loadLogs();
      }
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        this.log_items = res.data;
        this.log_rows = res.data.length;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
