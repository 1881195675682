<template>
  <div>
    <validation-observer ref="AssetEditForm" #default="{ invalid }">
      <form ref="form" @submit.prevent="scanNow">
        <!-- <b-form-input
        id="agent_conf_id"
        v-model="agent_conf_id"
        name="agent_conf_id"
        hidden
      /> -->
        <b-form-group label-for="urls" v-if="!is_group">
          <template #label>
            <span>URLs</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
            <b-button
              class="ml-1"
              style="padding: 0.186rem 1rem !important"
              @click="gotoUrl()"
              variant="outline-success"
              size="sm"
              >+
            </b-button>
          </template>
          <validation-provider
            #default="{ errors }"
            name="url"
            rules="required"
          >
            <v-select
              v-model="urlName"
              label="text"
              placeholder="--Select URL--"
              :options="url_options"
              autocomplete
              style="width: 100%"
              :reduce="(urlName) => urlName.value"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="" label-for="is_authorised">
          <b-form-checkbox
            id="checkbox-1"
            v-model="is_authorised"
            name="is_authorised"
            value="true"
            unchecked-value="false"
          >
            Authorised Scan
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          label="Username"
          label-for="username"
          v-if="is_authorised === true || is_authorised === 'true'"
        >
          <template #label>
            <span>Username</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="username"
            rules="required"
          >
            <b-form-input
              id="username"
              :state="errors.length > 0 ? false : null"
              v-model="username"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Password"
          label-for="password"
          v-if="is_authorised === true || is_authorised === 'true'"
        >
          <template #label>
            <span>Password</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="password"
            rules="required"
          >
            <b-form-input
              id="password"
              :state="errors.length > 0 ? false : null"
              v-model="password"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Is scoped -->
        <b-form-group label="" label-for="is_scoped">
          <b-form-checkbox
            id="checkbox-2"
            v-model="is_scoped"
            name="is_scoped"
            value="true"
            unchecked-value="false"
          >
            Scoped Scan
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          label="Include"
          label-for="include"
          v-if="is_scoped === true || is_scoped === 'true'"
        >
          <b-form-input id="include" v-model="include"></b-form-input>
        </b-form-group>
        <b-form-group
          label="Exclude"
          label-for="exclude"
          v-if="is_scoped === true || is_scoped === 'true'"
        >
          <b-form-input id="exclude" v-model="exclude"></b-form-input>
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
          :disabled="invalid || disable || urlName === null"
          size="sm"
        >
          <span v-if="isLoading"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else>Scan</span>
        </b-button>
      </form>
    </validation-observer>
    <b-modal
      title="Add New URL"
      hide-footer
      no-close-on-backdrop
      size="lg"
      v-model="urlModal"
    >
      <CreateAssetUrl :assetId="asset_id" :closeModal="closeModal" />
    </b-modal>
  </div>
</template>
<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { integer } from "vee-validate/dist/rules";
import CreateAssetUrl from "../components/asset_url/add.vue";
export default {
  data() {
    return {
      userId: localStorage.getItem("userid"),
      customScanInputMeta: {},
      username: "",
      password: "",
      is_authorised: false,
      is_scoped: false,
      include: "",
      exclude: "",
      url_options: [],
      urlName: null,
      urlModal: false,
      isLoading: false,
      disable: false,
    };
  },
  props: {
    asset_id: {
      type: Number,
      required: true,
    },
    agent_type_id: {
      type: Number,
      required: true,
    },
    scan_input_meta: {
      type: String,
      required: true,
    },
    callBack: {
      type: Function,
      required: true,
    },
    // If is_group is true, asset_id passed is actually group_id
    // We should trigger a group based scheduled instant scan
    is_group: {
      type: Boolean,
      required: false,
    },
    asset_group_id: {
      type: Number,
      required: true,
    },
  },
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    vSelect,
    CreateAssetUrl,
    BSpinner,
  },
  created: function () {
    this.load();
    if (!this.is_group) {
      this.loadAssetUrls();
    }
  },
  methods: {
    load: function () {
      //   this.customScanInputMeta = this.scan_input_meta;
    },
    scheduleInstantGroupScan: function (scan_input_meta) {
      let data = {
        agent_type: this.agent_type_id,
        asset_group: this.asset_group_id,
        scan_created_by: this.userId,
        scan_input_meta: scan_input_meta.toString(),
        scan_schedule_type: 0,
        url_id: null,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "scan/schedule/",
      };
      var self = this;
      this.$http(options).then((res) => {
        const callBackParams = "modal-customize-meta-burp";
        if (res.data.schedule_id) {
          this.callBack(callBackParams);
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Successfully added the scan request",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.callBack(callBackParams);
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Trouble while processing the scan request",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    loadAssetUrls() {
      this.open_ports = [];
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/asset-url?asset_id=" +
          this.asset_id,
      };
      var self = this;
      this.$http(options).then((res) => {
        res.data.results.map(function (value, key) {
          let a = {
            value: res.data.results[key].url_id,
            text: res.data.results[key].url,
          };
          self.url_options.push(a);
        });
      });
    },
    scanNow: function () {
      // Open pop up
      //this.$parent.$refs['modal-customize-meta'].hide()
      this.disable = true;
      this.isLoading = true;
      //   Prepare scan meta here:
      if (this.is_authorised) {
        this.customScanInputMeta["application_logins"] = {
          username: this.username,
          password: this.password,
        };
      }
      if (this.is_scoped && (this.include != "" || this.exclude != "")) {
        let scope = {};
        if (this.include != "") scope["include"] = { rule: this.include };
        if (this.exclude != "") scope["exclude"] = { rule: this.exclude };
        this.customScanInputMeta["scope"] = scope;
      }
      this.customScanInputMeta = JSON.stringify(this.customScanInputMeta);
      console.log(this.customScanInputMeta);
      if (this.is_group) {
        this.scheduleInstantGroupScan(this.customScanInputMeta);
      } else {
        let data = {
          agent_type: this.agent_type_id,
          asset_id: this.asset_id,
          scan_created_by: this.userId,
          scan_input_meta: this.customScanInputMeta,
          url_id: this.urlName,
        };
        const options = {
          method: "POST",
          data: data,
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "scan/scan/",
        };
        var self = this;
        this.$http(options).then((res) => {
          const callBackParams = "modal-customize-meta-burp";
          if (res.data.scan_created_by) {
            this.callBack(callBackParams);
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Successfully added the scan request",
                icon: "EditIcon",
                variant: "success",
              },
            });
          } else {
            this.callBack(callBackParams);
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Trouble while processing the scan request",
                icon: "EditIcon",
                variant: "danger",
              },
            });
          }
          this.disable = false;
          this.isLoading = false;
        });
      }
    },
    gotoUrl() {
      this.urlModal = true;
    },
    closeModal() {
      this.urlModal = false;
      this.loadAssetUrls();
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>