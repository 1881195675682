<template>
    <!-- form -->
    <validation-observer
    ref="ScanScheduleForm"
    #default="{invalid}"      
    >
    <b-form
    class="auth-login-form mt-2"
    @submit.prevent="scheduleScan"
    >
        <!-- Scanner-->
        <b-form-group
            label="Schedule"
            label-for="Schedule">
            <validation-provider
                #default="{ errors }"
                name="schedule_selected"
                rules="required">
                <b-form-select  
                v-model="schedule_selected" 
                :options="schedule"
                :state="errors.length > 0 ? false:null"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
        </b-form-group>

        <b-button
            type="submit"
            variant="primary"
            :disabled="invalid"
        >
            Schedule
        </b-button>
    </b-form>
    </validation-observer>

</template>
<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BTable } from 'bootstrap-vue'
import { BModal,BPagination,BPaginationNav, BButton, BRow, BCol, BBadge, BCard,BCardHeader,BCardBody,BFormGroup, BFormInput, BFormSelect,BForm,BFormTextarea} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { integer } from 'vee-validate/dist/rules'
export default {
  data() {
    return {
      userId: localStorage.getItem('userid'),
      schedule_selected:"",
      schedule:[
        { value: null, text: '--Select Schedule--' },
        { value: 1, text: 'Daily' },
        { value: 2, text: 'Weekdays [Mon- Fri]' },
        { value: 3, text: 'Weekends [Sat or Sun]' },
        { value: 5, text: 'Monthly ' }
      ],
    }
  },
  props: {
    asset_id: {
      type: Number,
      required: true,
    },
    agent_type_id: {
      type: Number,
      required: true,
    },
    agent_type:{
      type: String,
      required: true
    },
    callBack:{
        type: Function,
        required: true
    }
  },
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormSelect
  },
  created(){
    this.load()
  },
  methods:{
    load: function () {},
    scheduleScan: function(){
        // Open pop up
        
        let data = {
            agent_type: this.agent_type_id,
            asset_id: this.asset_id,
            scan_created_by : this.userId,
            is_scan_scheduled: 1,
            scan_schedule_type:this.schedule_selected,
        }
        const options = {
            method: 'POST',
            data: data,
            headers: { 'content-type': 'application/json' },
            url:  process.env.VUE_APP_BASEURL+'scan/schedule/',
        }
        var self = this
        this.$http(options).then( ( res ) => {
            console.log(res,">>>>>>>>>>")
            const callBackParams = "modal-schedule-scan-IPReputation";
            if(res.data.schedule_created_by){
            self.$toast({
                component: ToastificationContent,
                props: {
                    title: "Successfully added the scan request",
                    icon: 'EditIcon',
                    variant: 'success',
                },
            })
            this.callBack(callBackParams);
            this.load();
            }
        })
        },
  },

  }
  </script>