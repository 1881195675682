<template>
  <div>
    <b-tabs>
      <b-tab title="Attack Surface Details">
        <b-card-code no-body>
          <div
            v-if="
              items &&
              items.nmap_result &&
              items.nmap_result.ports &&
              items.nmap_result.ports.length
            "
          >
            <b-table-simple caption-top responsive class="rounded-bottom mb-0">
              <b-thead head-variant="light">
                <b-tr>
                  <b-th>IP Address</b-th>
                  <b-th>Port</b-th>
                  <b-th>Protocol</b-th>
                  <b-th style="white-space: nowrap">Vulnerability Count</b-th>
                  <b-th style="text-align: center">URL</b-th>
                  <b-th style="text-align: center">Technology</b-th>
                  <b-th style="text-align: center">Details</b-th>
                  <b-th style="text-align: center">Screenshots</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td
                    :rowspan="items.nmap_result.ports.length + 1"
                    class="text-center"
                  >
                    {{ items.nmap_result.address }}
                  </b-td>
                </b-tr>
                <b-tr
                  v-for="(nmapPort, npid) in items.nmap_result.ports"
                  :key="npid"
                >
                  <b-td> {{ nmapPort.portid }}/{{ nmapPort.protocol }} </b-td>
                  <b-td>{{ nmapPort.service_name }}</b-td>
                  <b-td style="white-space: nowrap">
                    <span
                      class="align-items-center"
                      v-for="(key, value) in items.count_by_port[
                        nmapPort.portid
                      ]"
                      :key="key"
                    >
                      <b-avatar
                        v-if="value === 'critical'"
                        style="background-color: #a020f0 !important"
                        class="cursor-pointer text-capitalize"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.top
                        :title="value.charAt(0).toUpperCase() + value.slice(1)"
                        button
                        @click="
                          $router.push({
                            path: '/assets/vulnerabilities',
                            query: {
                              port: nmapPort.portid,
                              asset_name: asset_name,
                              severity: 'critical',
                              tabIndex: 1,
                            },
                          })
                        "
                      >
                        {{ key }}
                      </b-avatar>
                      <b-avatar
                        v-if="value === 'high'"
                        variant="danger"
                        class="cursor-pointer text-capitalize"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.top
                        :title="value.charAt(0).toUpperCase() + value.slice(1)"
                        button
                        @click="
                          $router.push({
                            path: '/assets/vulnerabilities',
                            query: {
                              port: nmapPort.portid,
                              asset_name: asset_name,
                              severity: 'high',
                              tabIndex: 1,
                            },
                          })
                        "
                      >
                        {{ key }}
                      </b-avatar>
                      <b-avatar
                        v-if="value === 'medium'"
                        variant="warning"
                        class="cursor-pointer text-capitalize"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.top
                        :title="value.charAt(0).toUpperCase() + value.slice(1)"
                        button
                        @click="
                          $router.push({
                            path: '/assets/vulnerabilities',
                            query: {
                              port: nmapPort.portid,
                              asset_name: asset_name,
                              severity: 'medium',
                              tabIndex: 1,
                            },
                          })
                        "
                      >
                        {{ key }}
                      </b-avatar>
                      <b-avatar
                        v-if="value === 'low'"
                        variant="success"
                        class="cursor-pointer text-capitalize"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.top
                        :title="value.charAt(0).toUpperCase() + value.slice(1)"
                        button
                        @click="
                          $router.push({
                            path: '/assets/vulnerabilities',
                            query: {
                              port: nmapPort.portid,
                              asset_name: asset_name,
                              severity: 'low',
                              tabIndex: 1,
                            },
                          })
                        "
                      >
                        {{ key }}
                      </b-avatar>
                      <b-avatar
                        v-if="value === 'info'"
                        style="background-color: #7367f0 !important"
                        class="cursor-pointer text-capitalize"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.top
                        :title="value.charAt(0).toUpperCase() + value.slice(1)"
                        button
                        @click="
                          $router.push({
                            path: '/assets/vulnerabilities',
                            query: {
                              port: nmapPort.portid,
                              asset_name: asset_name,
                              severity: 'info',
                              tabIndex: 1,
                            },
                          })
                        "
                      >
                        {{ key }}
                      </b-avatar>
                    </span>
                  </b-td>
                  <b-td>
                    <span
                      v-for="(whatwebPort, wp) in items.whatweb_result"
                      :key="wp"
                    >
                      <ul
                        v-if="
                          parseInt(whatwebPort.port) ===
                          parseInt(nmapPort.portid)
                        "
                      >
                        <li>
                          <strong>Target:</strong> {{ whatwebPort.target
                          }}<br />
                          <strong>HTTP Status:</strong>
                          {{ whatwebPort.http_status }}<br />
                        </li>
                      </ul>
                    </span>
                  </b-td>
                  <b-td>
                    <span
                      v-for="(whatwebPort, wpid) in items.whatweb_result"
                      :key="wpid"
                    >
                      <ul
                        v-if="
                          parseInt(whatwebPort.port) ===
                          parseInt(nmapPort.portid)
                        "
                      >
                        <li><strong>HTML :</strong> {{ whatwebPort.html }}</li>
                        <li>
                          <strong>jquery :</strong> {{ whatwebPort.jquery }}
                        </li>
                        <li>
                          <strong>nginx :</strong> {{ whatwebPort.nginx }}
                        </li>
                        <li>
                          <strong>wordpress :</strong>
                          {{ whatwebPort.wordpress }}
                        </li>
                        <li>
                          <strong>x_hacker :</strong> {{ whatwebPort.x_hacker }}
                        </li>
                        <li>
                          <strong>Powered By :</strong
                          >{{ whatwebPort.poweredby }}
                        </li>
                      </ul>
                    </span>
                  </b-td>
                  <b-td>
                    <span
                      v-for="(whatwebPort, wpid) in items.whatweb_result"
                      :key="wpid"
                    >
                      <ul
                        v-if="
                          parseInt(whatwebPort.port) ===
                          parseInt(nmapPort.portid)
                        "
                      >
                        <li>
                          <strong>HTTP Server OS:</strong>
                          {{ whatwebPort.http_server_os }}
                        </li>
                        <li>
                          <strong>HTTP Server :</strong>
                          {{ whatwebPort.http_server }}
                        </li>
                        <li>
                          <strong>Title :</strong> {{ whatwebPort.title }}
                        </li>
                        <li v-if="whatwebPort.redirect_location != null">
                          <strong>Redirect Location :</strong>
                          {{ whatwebPort.redirect_location }}
                        </li>
                        <li>
                          <strong>Uncommon Header :</strong>
                          {{ whatwebPort.uncommon_headers }}
                        </li>
                      </ul>
                    </span>
                  </b-td>
                  <b-td>
                    <div v-for="(assetImage, index) in imageUrls" :key="index">
                      <span v-for="imageport in assetImage" :key="imageport">
                        <span
                          v-if="
                            parseInt(imageport.port) ===
                            parseInt(nmapPort.portid)
                          "
                        >
                          <!-- <a :href="asset_name" target="_blank"> -->
                          <span
                            class="cursor-pointer"
                            @click="gotoImageModal(imageport.url)"
                          >
                            <img
                              :key="index"
                              :src="imageport.url"
                              height="150px"
                              width="200px"
                              alt="Image"
                            />
                          </span>
                          <!-- </a> -->
                        </span>
                      </span>
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div v-else>
            <div
              class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
            >
              <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
              <h3 class="font-weight-bolder">No Data Found</h3>
            </div>
          </div>
          <b-modal
            v-model="openImageModal"
            hide-footer
            no-close-on-backdrop
            size="lg"
          >
            <img :key="index" :src="imageurl" width="100%" alt="Image" />
          </b-modal>
        </b-card-code>
      </b-tab>
      <b-tab title="Domain info">
        <div class="d-flex justify-content-end">
          <b-button
            variant="primary"
            size="sm"
            :disabled="is_disable"
            @click="initiateDomainScan()"
          >
            <span v-if="is_DomainScan"
              ><b-spinner type="border" small></b-spinner> Please wait</span
            >
            <span v-else>Fetch Domain Info</span>
          </b-button>
        </div>
        <DomainInfo :assetId="asset_id" :tabIndex="2" />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BCard,
  BTable,
  BBadge,
  BButton,
  BModal,
  BCardText,
  BSpinner,
  BPaginationNav,
  BDropdown,
  BDropdownForm,
  BFormCheckbox,
  BTableColumn,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BFormInput,
  BAvatar,
  VBTooltip,
  BTab,
  BTabs,
} from "bootstrap-vue";
import moment from "moment";
import BSTableSimple from "@/components/bs_table/TableSimple";
import Ripple from "vue-ripple-directive";
import DomainInfo from "../../AttackSurface/components/domainInfo/index.vue";
export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BButton,
    BModal,
    BCardText,
    BSpinner,
    BPaginationNav,
    BDropdown,
    BDropdownForm,
    BFormCheckbox,
    BTableColumn,
    BSTableSimple,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BCardCode,
    BFormInput,
    BAvatar,
    BTab,
    BTabs,
    DomainInfo,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      items: [],
      fields: [
        { label: "#", visible: true },
        { label: "IP Address", visible: true },
        { label: "OS", visible: true },
        { label: "Port", visible: true },
        { label: "Protocol", visible: true },
        { label: "URL", visible: false },
        { label: "Technology", visible: true },
      ],
      xfields: [
        { key: "index", label: "#", visible: true },
        { key: "address", label: "IP Address", visible: true },
        { key: "asset_name", label: "Hostname", visible: true },
        { key: "os", label: "OS", visible: false },
        { key: "protocol", label: "Protocol", visible: true },
        { key: "technology", label: "Technology", visible: true },
        { key: "url", label: "URL", visible: false },
      ],
      loading: false,
      asset_id: null,
      imageUrls: [],
      openImageModal: false,
      imageurl: "",
      is_DomainScan: false,
      is_disable: false,
    };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  props: {
    asset_id: {
      type: Number,
      required: true,
    },
    asset: {
      type: Array,
      required: true,
    },
    asset_name: {
      type: String,
      required: true,
    },
  },
  watch: {
    asset_id: function (newVal, oldVal) {
      this.load();
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load: function () {
      let url =
        process.env.VUE_APP_BASEURL +
        "asset/org-asset/" +
        this.asset_id +
        "/asset-surface-attack";
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(options).then((res) => {
        this.items = res.data;
        const assetImageUrls = []; // Array to store image URLs for the current asset
        if (this.items.whatweb_result) {
          this.items.whatweb_result.forEach((whatwebPort, wp) => {
            if (whatwebPort.image_data) {
              this.getImageUrl(whatwebPort.image_data)
                .then((imageUrl) => {
                  assetImageUrls.push({
                    port: whatwebPort.port,
                    url: imageUrl,
                  });
                })
                .catch((error) => {
                  console.error("Error getting image URL:", error);
                });
            }
          });
        }
        this.imageUrls.push(assetImageUrls); // Add the array for the current asset
      });
    },
    getImageUrl(imageData) {
      return new Promise((resolve, reject) => {
        const imageInfo = JSON.parse(imageData);
        const data = {
          file_obj: imageInfo,
        };
        const options = {
          method: "POST",
          data: data,
          url:
            process.env.VUE_APP_BASEURL +
            "asset/attack-surface/download-screenshot/",
          responseType: "blob",
        };

        this.$http(options)
          .then((res) => {
            console.log("Image Data:", res.data);
            const url = window.URL.createObjectURL(new Blob([res.data]));
            resolve(url);
          })
          .catch((error) => {
            console.error("Error fetching image:", error);
            reject(error);
          });
      });
    },
    gotoImageModal(imageurl) {
      this.imageurl = imageurl;
      this.openImageModal = true;
    },
    initiateDomainScan() {
      this.is_disable = true;
      this.is_DomainScan = true;
      let url =
        process.env.VUE_APP_BASEURL +
        "asset/domain-digger/scan?asset_id=" +
        this.asset_id;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(options)
        .then((res) => {
          if (res.data.status == "success") {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          }
          this.is_disable = false;
          this.is_DomainScan = false;
        })
        .catch((err) => {
          console.log(err);
          this.is_disable = false;
          this.is_DomainScan = false;
        });
    },
  },
};
</script>
